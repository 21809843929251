import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import colors from '../../constants/colors';

const StyledSwitch = styled(Switch)({
  '& .MuiSwitch-switchBase': {
    color: colors.groupizeBrand,
    '& + .MuiSwitch-track': {
      backgroundColor: colors.grey400,
    },
    '&.Mui-checked': {
      color: colors.groupizeBrand,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: colors.groupizeBrand,
  },
});

const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    color: colors.grey600,
  },
});

function SwitchField({
  label,
  value,
  onChange,
}) {
  return (
    <StyledFormControlLabel
      control={(
        <StyledSwitch
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      )}
      label={label}
    />
  );
}

SwitchField.defaultProps = {
  label: '',
  value: false,
};

SwitchField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default SwitchField;
