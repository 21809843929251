import * as yup from 'yup';

export function createYupSchema(schema, config) {
  const thisSchema = schema;
  const { name, validationType, required } = config;

  if (!yup[validationType]) {
    return thisSchema;
  }

  if (required) {
    let validator = yup[validationType]();
    validator = validator.required();
    thisSchema[name] = validator;
  }

  return thisSchema;
}
