import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  HeroContentArea,
  HeroTitle,
  HeroDate,
  HeroAddress,
  HeroBy,
  HeroDescription,
} from './styles';

function Desc({ content }) {
  return (
    <HeroDescription>
      <div className="ql-editor" dangerouslySetInnerHTML={{ __html: content }} />
    </HeroDescription>
  );
}

Desc.propTypes = {
  content: PropTypes.string.isRequired,
};

function Title({ content }) {
  return (
    <HeroTitle>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </HeroTitle>
  );
}

Title.propTypes = {
  content: PropTypes.string.isRequired,
};

function HeroContent(props) {
  const {
    title,
    address,
    description,
    contentPosition,
    showContent,
    showDates,
    showTimes,
    startDate,
    startTime,
    endDate,
    endTime,
    byCompany,
    showMediaContent,
  } = props;

  let dateText = '';
  const hasStartTime = showTimes && startTime?.length > 0;
  const hasEndTime = showTimes && endTime?.length > 0;

  const startDateText = hasStartTime ? `${startDate} ${startTime}` : startDate;
  const endDateText = hasEndTime ? `${endDate} ${endTime}` : endDate;

  let from = moment(startDateText);
  let to = moment(endDateText);

  if (startDate !== endDate) {
    const dateRange = [];
    const startFormat = (hasStartTime)
      ? 'ddd, MMM DD, YYYY (hh:mm a)'
      : 'ddd, MMM DD, YYYY';
    const endFormat = (hasEndTime)
      ? 'ddd, MMM DD, YYYY (hh:mm a)'
      : 'ddd, MMM DD, YYYY';

    from = from.isValid() ? from.format(startFormat) : null;
    to = to.isValid() ? to.format(endFormat) : null;
    dateRange.push(from, to);
    dateText = dateRange.filter((x) => x).join(' - ');
  } else {
    const timeRange = [];
    const eventDate = from.isValid() ? from.format('ddd, MMM DD, YYYY') : null;
    from = (hasStartTime && from.isValid()) ? from.format('hh:mm a') : null;
    to = (hasEndTime && to.isValid()) ? to.format('hh:mm a') : null;
    timeRange.push(from, to);
    dateText = (timeRange.length > 0) ? `${eventDate} (${timeRange.filter((x) => x).join(' - ')})` : eventDate;
  }

  return (
    <HeroContentArea
      hasMedia={showMediaContent}
      contentPosition={contentPosition}
    >
      {showContent && (
        <>
          {byCompany && (
            <HeroBy>
              {byCompany}
            </HeroBy>
          )}

          <Title content={title} />

          {showDates && (
            <HeroDate>
              {' '}
              {dateText}
              {' '}
            </HeroDate>
          )}

          <HeroAddress>
            {address}
          </HeroAddress>

          {description && (
            <Desc content={description} />
          )}
        </>
      )}
    </HeroContentArea>
  );
}

HeroContent.defaultProps = {
  title: '',
  address: '',
  description: '',
  contentPosition: 'left',
  eventDateRange: null,
  byCompany: '',
  showContent: false,
};

HeroContent.propTypes = {
  title: PropTypes.string,
  address: PropTypes.string,
  description: PropTypes.string,
  contentPosition: PropTypes.oneOf(['left', 'center', 'right']),
  byCompany: PropTypes.string,
  showContent: PropTypes.bool,
};

export default HeroContent;
