import styled, { css } from 'styled-components';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import colors from '../constants/colors';

export const ToggleScreenBtn = styled(IconButton)`
  font-size: 1.4em !important;
  border-radius: 0 !important;
  width: 3rem;
`;

export const SettingsPanelWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${colors.white};
  margin-left: auto;
`;

export const SideMainPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;

  background-color: ${colors.grey50};
  font-size: 1em;
  border: 1px solid ${grey[200]};

  box-sizing: border-box;
  margin-right: -${(props) => props.panelWidth || '350px'};
  max-width: calc(80vw);
  transition: margin 0.2s ease;
  width: ${(props) => props.panelWidth || '350px'};

  ${(props) => props.open && css`
    margin-right: 0;
  `}

  ${(props) => props.self && css`
    position: fixed;
    top: 0;
    right: -${props.panelWidth || '350px'};
    bottom: 0;

    box-shadow: 0 0 30px rgba(66, 66, 66, 0.3);
    margin-right: 0;
    overflow: auto;
    transition: right 0.2s ease;
    z-index: 998;

    ${props.open && css`
      right: 0;
    `}
  `}
`;

export const SidePanelHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  justify-content: center;

  background-color: ${colors.groupizeBrand};
  border-bottom: 1px solid ${colors.grey50};
  box-sizing: border-box;
  color: ${colors.grey50};
  font-size: 0.9em;
  text-transform: uppercase;
`;

export const SideMainPanelContent = styled.div`
  background-color: ${colors.grey50};
  color: ${colors.grey800};
  flex: 1 1 auto;
`;

export const SidePanelTitle = styled(SidePanelHeader)`
  background-color: ${colors.grey100};
  color: ${colors.grey600};
`;

export const SideActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 1.2em;
  padding-left: 1em;

  .icon-btn {
    margin-left: auto;
    font-size: 1.4em !important;
    border-radius: 0 !important;
    color: ${colors.grey50};
  }

  .small-icon {
    height: 0.7em;
    width: 0.7em;
  }
`;

export const HeaderSubTitle = styled.p`
  font-size: 0.7em;
  line-height: 1.4;
  margin: 0;
  padding: 0 1em;
  text-align: center;
  text-transform: none;
`;
