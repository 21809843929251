import styled, { css } from 'styled-components';
import { blue, grey } from '@mui/material/colors';

export const AvatarWrapper = styled.div`
  border: 2px solid ${blue[600]};
  border-radius: 500rem;
  background-color: ${grey[50]};

  height: 5rem;
  width: 5rem;

  ${(props) => props.small && css`
    height: 2rem;
    width: 2rem;
  `}

  ${(props) => props.bgImage && css`
    background-image: url(${props.bgImage});
    background-size: cover;
    background-position: center;
  `}
`;
