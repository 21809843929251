import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import colors from '../../constants/colors';

const StyledButton = styled(MuiButton)((props) => ({
  padding: '0.5rem 1rem',
  minHeight: '35px',
  overflow: 'hidden',
  textTransform: 'capitalize',
  backgroundColor: props.bgcolor || colors.groupizeBrand,
  color: props.textcolor || colors.groupizeBrand,
  '&.MuiButton-outlined': {
    backgroundColor: 'transparent',
    borderColor: props.bgcolor || colors.groupizeBrand,
    '&:hover': {
      backgroundColor: colors.grey50,
    },
  },
  '&.MuiButton-contained': {
    backgroundColor: props.bgcolor || colors.groupizeBrand,
    borderColor: props.bgcolor || colors.groupizeBrand,
    color: props.textcolor || colors.white,
    '&:hover': {
      backgroundColor: props.bgcolor || colors.groupizeBrand,
      borderColor: props.bgcolor || colors.groupizeBrand,
    },
  },
  '&.MuiButton-text': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
}));

function Button({
  children,
  ...rest
}) {
  return (
    <StyledButton {...rest}>
      {children}
    </StyledButton>
  );
}

Button.defaultProps = {
  disabled: false,
  variant: 'outlined',
  size: 'medium',
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  bgcolor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  textcolor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
};

export default Button;
