import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WYSIWYGEditor from '../Form/WYSIWYGEditor/WYSIWYGEditor';
import TextField from '../Form/TextField';
import FilePicker from '../Form/FilePicker/FilePicker';
import { FlexFlow } from '../helpers/DisplayHelpers';
import SelectField from '../Form/SelectField/SelectField';

function MessageSendForm({
  allRecipients,
  handleChangeFields,
  hasRecipients,
  filepickerOptions,
  messageData,
  formErrors,
  showErrors,
}) {
  const [fields, setFields] = useState(messageData);
  const [usedFields, setUsedFields] = useState([]);

  const updateUsedFields = (name) => {
    if (!usedFields.includes(name)) {
      usedFields.push(name);
      setUsedFields(usedFields);
      handleChangeFields(fields);
    }
  };

  const hasErrorMessage = (name) => {
    const messages = [];
    formErrors.forEach((e) => {
      if ((e.path === name) && ((usedFields.includes(name) || showErrors))) {
        messages.push(e.message);
      }
    });
    return messages;
  };

  const handleChangeValue = (value, name) => {
    const newFields = { ...fields, [name]: value };
    setFields(newFields);
    handleChangeFields(newFields);
  };

  const handleChange = (e, name) => {
    handleChangeValue(e.target.value, name);
  };

  return (
    <FlexFlow column margin="0 0 2em" splitSpace="1em">
      {hasRecipients && (
        <>
          <SelectField
            multiple
            nomargin
            id="recipients"
            label="Send to"
            name="send_to"
            placeholder="Select recipients"
            items={allRecipients}
            value={fields.recipients}
            onChange={(v) => handleChangeValue(v, 'recipients')}
            onBlur={() => updateUsedFields('recipients')}
            helperText={hasErrorMessage('recipients').length > 0 ? hasErrorMessage('recipients').join(', ') : ''}
          />

          <SelectField
            nomargin
            id="label"
            label="Label"
            name="label"
            items={['property', 'internal', 'other'].map((c) => ({ value: c, label: c }))}
            value={fields.label}
            onChange={(v) => handleChangeValue(v, 'label')}
            onBlur={() => updateUsedFields('label')}
            helperText={hasErrorMessage('label').length > 0 ? hasErrorMessage('label').join(', ') : ''}
          />

          <TextField
            nomargin
            id="internal_signature_subject"
            label="Subject"
            value={fields.subject}
            onChange={(e) => handleChange(e, 'subject')}
            onBlur={() => updateUsedFields('subject')}
            error={hasErrorMessage('subject').length > 0}
            helperText={hasErrorMessage('subject').length > 0 ? hasErrorMessage('subject').join(', ') : ''}
          />
        </>
      )}

      <WYSIWYGEditor
        id="send_content"
        value={fields.message}
        onChange={(v) => handleChangeValue(v, 'message')}
        placeholder="Enter message"
        options={['inline', 'fontSize', 'list', 'link']}
      />

      {hasRecipients && (
        <FilePicker
          hasWrapper
          {...filepickerOptions}
          id="number"
          label="Choose File"
          btnAddName="CLICK HERE TO ATTACH FILES"
          btnRemoveName=""
          onSubmit={(file) => handleChangeValue(file || '', 'attachment')}
          fileUrl={fields.attachment.length > 0 ? fields.attachment[0].url : ''}
          fileName={fields.attachment.length > 0 ? fields.attachment[0].filename : ''}
        />
      )}
    </FlexFlow>
  );
}

MessageSendForm.defaultProps = {
  messageData: {
    subject: '',
    message: '',
    label: '',
    attachment: '',
    recipients: [],
  },
  allRecipients: [],
  hasRecipients: true,
  formErrors: [],
  showErrors: false,
};

MessageSendForm.propTypes = {
  messageData: PropTypes.shape(),
  handleChangeFields: PropTypes.func.isRequired,
  filepickerOptions: PropTypes.shape().isRequired,
  allRecipients: PropTypes.arrayOf(PropTypes.shape()),
  hasRecipients: PropTypes.bool,
  formErrors: PropTypes.arrayOf(PropTypes.shape),
  showErrors: PropTypes.bool,
};

export default MessageSendForm;
