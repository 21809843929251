import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Marker, OverlayView } from '@react-google-maps/api';

function GMapMarker({
  isActive: defaultIsActive,
  id,
  activeId,
  callback,
  markerContent,
  position,
  ...rest
}) {
  const [isActive, setActive] = useState(defaultIsActive);

  useEffect(() => {
    setActive(activeId === id);
  }, [activeId, id]);

  const handleMarkerClick = () => {
    setActive(!isActive);

    if (callback instanceof Function) {
      callback();
    }
  };

  return (
    <Marker {...rest} position={position} onClick={handleMarkerClick}>
      {isActive && markerContent && (
        <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
          {markerContent}
        </OverlayView>
      )}
    </Marker>
  );
}

GMapMarker.defaultProps = {
  position: { lat: -34.397, lng: 150.644 },
  isActive: false,
  defaultAnimation: 0,
  activeId: 0,
  callback: null,
  markerContent: null,
  icon: '',
  id: 0,
};

GMapMarker.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  icon: PropTypes.string,
  markerContent: PropTypes.element,
  position: PropTypes.shape(),
  activeId: PropTypes.number,
  callback: PropTypes.func,
  defaultAnimation: PropTypes.number,
  isActive: PropTypes.bool,
};

export default GMapMarker;
