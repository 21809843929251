/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Done';
import { grey } from '@mui/material/colors';
import { styled as MuiStyled } from '@mui/material/styles';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { FlexFlow } from '../Common/helpers/DisplayHelpers';
import Button from '../Common/Form/Button';

export const StyledTypography = MuiStyled(Typography)({
  color: grey[800],
});

export const StyledDialogActions = MuiStyled(DialogActions)({
  flexFlow: 'row-reverse nowrap',
  alignItems: 'stretch',
  backgroundColor: grey[50],
  '& > button': {
    marginLeft: '1em',
  },
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'grc-cf-dialogs',
});

const TransitionUp = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

function ConfirmationDialog({
  open,
  title,
  subtitle,
  maxWidth,
  fullWidth,
  fullScreen,
  cancelBtnCopy,
  confirmBtnCopy,
  handleCancel,
  handleConfirm,
  showTitle,
  showTitleCloseBtn,
  showActionBtns,
  disableBackdropClick,
  children,
  ...rest
}) {
  const handleOnClose = (event, reason) => {
    if (disableBackdropClick && reason !== 'backdropClick') {
      handleCancel(event, reason);
    }
  };
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Dialog
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={open}
        onClose={handleOnClose}
        aria-labelledby="Confirmation-title"
        TransitionComponent={TransitionUp}
        {...rest}
      >
        {showTitle && (
          <DialogTitle id="Confirmation-title">
            <FlexFlow grow align="center" justify="space-between">
              <FlexFlow column grow>
                <StyledTypography variant="h6">{title}</StyledTypography>

                {(subtitle && subtitle.length > 0) && (
                  <StyledTypography variant="body2">{subtitle}</StyledTypography>
                )}
              </FlexFlow>

              {showTitleCloseBtn && (
                <IconButton aria-label="close-modal" onClick={handleCancel}>
                  <CloseIcon />
                </IconButton>
              )}
            </FlexFlow>
          </DialogTitle>
        )}

        <DialogContent>
          {children}
        </DialogContent>

        {showActionBtns && (
          <StyledDialogActions>
            {cancelBtnCopy && (
              <Button
                bgcolor={grey[400]}
                textcolor={grey[800]}
                variant="outlined"
                onClick={handleCancel}
              >
                {cancelBtnCopy}
              </Button>
            )}
            {confirmBtnCopy && (
              <Button
                variant="outlined"
                onClick={handleConfirm}
                endIcon={<CheckIcon />}
              >
                {confirmBtnCopy}
              </Button>
            )}
          </StyledDialogActions>
        )}
      </Dialog>
    </StylesProvider>
  );
}

ConfirmationDialog.defaultProps = {
  open: false,
  showTitle: true,
  showTitleCloseBtn: false,
  showActionBtns: true,
  fullScreen: false,
  fullWidth: true,
  title: 'Please confirm',
  subtitle: '',
  maxWidth: 'sm',
  cancelBtnCopy: 'Cancel',
  confirmBtnCopy: 'Confirm',
  handleConfirm: () => (false),
  disableBackdropClick: false,
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  showTitle: PropTypes.bool,
  showTitleCloseBtn: PropTypes.bool,
  showActionBtns: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  maxWidth: PropTypes.string,
  cancelBtnCopy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  confirmBtnCopy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disableBackdropClick: PropTypes.bool,
};

export default ConfirmationDialog;
