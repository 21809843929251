import styled from 'styled-components';
import { orange } from '@mui/material/colors';

export const RateWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  align-items: center;
  padding: 0.5em 0;
  color: ${(props) => props.textColor || orange[800]};

  > svg {
    font-size: 1.2em;
    margin-right: 0.5em;
  }
`;
