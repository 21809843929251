import {
  grey,
  red,
  yellow,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  amber,
  orange,
  deepOrange,
  brown,
  blueGrey,
} from '@mui/material/colors';

export const toolBarOptions = {
  options: ['colorPicker', 'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      'black',
      'white',
      red[500],
      pink[500],
      purple[500],
      deepPurple[500],
      indigo[500],
      blue[500],
      lightBlue[500],
      cyan[500],
      teal[500],
      green[500],
      lightGreen[500],
      lime[500],
      yellow[500],
      amber[500],
      orange[500],
      deepOrange[500],
      brown[500],
      grey[500],
      blueGrey[500],
    ],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48],
  },
  fontFamily: {
    options: [
      'ProximaNova',
      'Roboto',
      'Open Sans',
      'Lato',
      'Ubuntu',
      'Lora',
      'Nunito',
      'Quicksand',
      'Indie Flower',
      'Anton',
      'Pacifico',
      'Permanent Marker',
      'sans-serif',
      'Arial',
      'Georgia',
      'Impact',
      'Tahoma',
      'Times New Roman',
      'Verdana',
    ],
  },
  inline: {
    inDropdown: true,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough'],

  },
  textAlign: {
    inDropdown: true,
    options: ['left', 'center', 'right', 'justify'],
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered'],

  },
  link: {
    inDropdown: true,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link', 'unlink'],
  },
};
