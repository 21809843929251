/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment-timezone';
import { FiAlertTriangle } from 'react-icons/fi';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { styled } from '@mui/material/styles';
import isSameDay from 'date-fns/isSameDay';

import {
  FieldWrapper,
  DateFieldInput,
  InputIcon,
  Required,
  StyledInput,
} from '../../helpers/styles';
import colors from '../../constants/colors';
import { FlexFlow } from '../../helpers/DisplayHelpers';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isCurrentDay',
})(({
  theme, isCurrentDay,
}) => ({
  ...(isCurrentDay && {
    backgroundColor: 'var(--gr-brand-color, #73BD5D) !important',
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: 'var(--gr-brand-color, #00ff00) !important',
      opacity: '0.9 !important',
    },
  }),
}));

function DateField({
  id,
  label,
  value,
  variant,
  minDate,
  maxDate,
  isDisabled,
  noMargin,
  flexGrow,
  onDateSelected,
  minheight,
  startAdornment,
  error,
  small,
  bgColor,
  textColor,
  borderColor,
  borderRadius,
  helperText,
  accentColor,
  isRequired,
  className,
  labelClassName,
}) {
  const [dateValue, setDateValue] = useState(value);
  const [isOpen, setOpen] = useState(false);

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!dateValue) {
      return <PickersDay {...pickersDayProps} />;
    }

    const thisDate = moment(dateValue).isValid() ? moment(dateValue).toDate() : null;
    const isCurrentDay = isSameDay(date, thisDate);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        isCurrentDay={isCurrentDay}
      />
    );
  };

  const dateSelected = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setDateValue(formattedDate);
    onDateSelected(formattedDate);
  };

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  const startContent = startAdornment ? (
    <InputIcon position="start">
      {startAdornment}
    </InputIcon>
  ) : null;

  const minMomentDate = moment(minDate).isValid() ? moment(minDate).toDate() : undefined;
  const maxMomentDate = moment(maxDate).isValid() ? moment(maxDate).toDate() : undefined;

  const isInline = ['simple', 'inline'].includes(variant);

  return (
    <FieldWrapper
      flexGrow={flexGrow}
      noMargin={noMargin}
      minheight={minheight}
      variant={variant}
      small={small}
      bgColor={bgColor}
      textColor={textColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      accentColor={accentColor}
      className={className}
    >
      <FlexFlow
        nowrap
        shrink
        grow
        splitSpace="0.5rem"
        align="center"
      >
        <FlexFlow
          column={!isInline}
          nowrap
          grow
          shrink
          align={isInline ? 'center' : 'stretch'}
        >
          {label && label.length > 0 && (
            <label htmlFor={id} className={labelClassName}>
              {isRequired && <Required />}
              {label}
            </label>
          )}

          <DateFieldInput
            variant={variant}
            error={error}
            bgColor={accentColor}
            color={textColor}
            className={isDisabled ? 'disabled' : ''}
          >
            {startContent}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id={id}
                open={isOpen}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                disabled={isDisabled}
                inputFormat="EEE, MMM dd, yyyy"
                disableMaskedInput
                value={moment(dateValue)}
                onChange={dateSelected}
                minDate={minMomentDate}
                maxDate={maxMomentDate}
                renderDay={renderWeekPickerDay}
                renderInput={({ inputRef, inputProps }) => {
                  const thisInputProps = { ...inputProps, placeholder: 'Enter date', readOnly: true };
                  return (
                    <StyledInput
                      onClick={() => setOpen(true)}
                      className="noborder"
                      ref={inputRef}
                      {...thisInputProps}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </DateFieldInput>
        </FlexFlow>

        {helperText && helperText.length > 0 && (
          <Tooltip title={helperText}>
            <FiAlertTriangle />
          </Tooltip>
        )}
      </FlexFlow>
    </FieldWrapper>
  );
}

DateField.defaultProps = {
  label: '',
  value: null,
  isDisabled: false,
  noMargin: false,
  variant: '',
  minheight: '0',
  onDateSelected: () => { },
  startAdornment: null,
  helpertext: '',
  flexGrow: 1,
  error: false,
  small: false,
  bgColor: 'transparent',
  textColor: 'inherit',
  borderColor: 'transparent',
  borderRadius: '0',
  accentColor: colors.groupizeBrand,
  helperText: '',
  isRequired: false,
  minDate: null,
  maxDate: null,
  className: '',
  labelClassName: '',
};

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  noMargin: PropTypes.bool,
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  small: PropTypes.bool,
  error: PropTypes.bool,
  isRequired: PropTypes.bool,
  onDateSelected: PropTypes.func,
  minheight: PropTypes.string,
  startAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  helpertext: PropTypes.string,
  flexGrow: PropTypes.number,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  helperText: PropTypes.string,
  accentColor: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default DateField;
