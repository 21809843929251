/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldWrapper, Required, StyledInput } from '../../helpers/styles';
import { useDebouncedEffect } from '../../../Hooks/useDebouncedEffect';

function TextArea({
  id,
  small,
  label,
  value,
  onChange,
  nomargin,
  minheight,
  flexGrow,
  isRequired,
  bgColor,
  textColor,
  borderColor,
  borderRadius,
  debounce,
  ...rest
}) {
  const [content, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useDebouncedEffect(() => onChange(content), debounce, [content]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <FieldWrapper
      small={small}
      nomargin={nomargin}
      flexGrow={flexGrow}
      minheight={minheight}
      bgColor={bgColor}
      textColor={textColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
    >
      {label && label.length > 0 && (
        <label htmlFor={id}>
          {isRequired && <Required />}
          {label}
        </label>
      )}

      <StyledInput
        id={id}
        value={content}
        multiline
        onChange={(e) => { handleChange(e.target.value); }}
        {...rest}
      />
    </FieldWrapper>
  );
}

TextArea.defaultProps = {
  label: '',
  value: '',
  minheight: '150px',
  nomargin: false,
  flexGrow: true,
  small: false,
  isRequired: false,
  bgColor: 'transparent',
  textColor: 'inherit',
  borderColor: 'transparent',
  borderRadius: '0',
  debounce: 300,
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  small: PropTypes.bool,
  isRequired: PropTypes.bool,
  minheight: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  nomargin: PropTypes.bool,
  flexGrow: PropTypes.bool,
  debounce: PropTypes.number,
};

export default TextArea;
