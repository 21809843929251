import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import {
  SidePanel,
  SideActions,
  SideMainPanel,
  SideInnerPanel,
  ToggleScreenBtn,
  SidePanelHeader,
  SideMainPanelContent,
  SettingsPanelWrapper,
  GroupizeBtn,
  SidePanelTitle,
  SideInnerPanelContent,
} from './styles';

function SettingsPanel({
  mainForm,
  innerForm,
  innerTitle,
  saveBtn,
  handleSave,
}) {
  const [isMainOpened, setMainOpened] = useState(false);
  const [isInnerOpened, setInnerOpened] = useState(false);

  const handleOpen = () => {
    setMainOpened(!isMainOpened);
    setInnerOpened(isMainOpened && !isInnerOpened);
  };

  const handleInnerOpen = () => {
    setInnerOpened(!isInnerOpened);
  };

  const handleSaveForm = () => {
    if (handleSave && typeof (handleSave) === 'function') {
      handleSave();
    }
  };

  return (
    <>
      <SettingsPanelWrapper>
        <ToggleScreenBtn onClick={handleOpen}>
          <SettingsIcon />
        </ToggleScreenBtn>
      </SettingsPanelWrapper>
      <SidePanel>
        <SideMainPanel open={isMainOpened}>
          <SidePanelHeader>
            <SideActions>
              {innerForm
                && (
                  <IconButton onClick={handleInnerOpen} className="icon-btn">
                    {isInnerOpened
                      ? <ForwardIcon className="small-icon" />
                      : <BackIcon className="small-icon" />}
                  </IconButton>
                )}

              <GroupizeBtn onClick={handleSaveForm}>
                {saveBtn}
              </GroupizeBtn>

              <IconButton onClick={handleOpen} className="icon-btn">
                <CloseIcon />
              </IconButton>
            </SideActions>
          </SidePanelHeader>

          <SideMainPanelContent>
            {mainForm}
          </SideMainPanelContent>

          {innerForm
            && (
              <SideInnerPanel open={(isMainOpened && isInnerOpened)}>
                <SidePanelTitle>
                  {innerTitle}
                </SidePanelTitle>
                <SideInnerPanelContent>
                  {innerForm}
                </SideInnerPanelContent>
              </SideInnerPanel>
            )}
        </SideMainPanel>
      </SidePanel>
    </>
  );
}

SettingsPanel.propTypes = {
  title: PropTypes.string,
  saveBtn: PropTypes.string,
  innerTitle: PropTypes.string,
  mainForm: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  innerForm: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  handleSave: PropTypes.func,
};

SettingsPanel.defaultProps = {
  title: '',
  saveBtn: 'Save',
  innerTitle: '',
  mainForm: <i />,
  innerForm: <i />,
  handleSave: () => true,
};

export default SettingsPanel;
