import React from 'react';
import PropTypes from 'prop-types';
import { AvatarWrapper } from './styles';

function Avatar({ small, avatarUrl }) {
  return (
    <AvatarWrapper
      small={small}
      bgImage={avatarUrl}
    />
  );
}

Avatar.defaultProps = {
  avatarUrl: '',
  small: false,
};

Avatar.propTypes = {
  small: PropTypes.bool,
  avatarUrl: PropTypes.string,
};

export default Avatar;
