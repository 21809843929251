/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import { FiAlertTriangle } from 'react-icons/fi';
import { FlexFlow } from '../../helpers/DisplayHelpers';
import {
  AutoCompleteContainer,
  AutoCompleteItem,
  FieldWrapper,
  StyledInput,
  StyledInlineInput,
  InputIcon,
} from '../../helpers/styles';

function LocationField({
  label,
  address: currentAddress,
  onLocationSelected,
  flexGrow,
  small,
  noMargin,
  minheight,
  startAdornment,
  variant,
  bgColor,
  textColor,
  borderColor,
  borderRadius,
  helperText,
  isDisabled,
  ...rest
}) {
  const [address, setAddress] = useState(currentAddress);

  const handleChange = (value) => {
    setAddress(value);
    if (value === '') {
      onLocationSelected(null, null);
    }
  };

  const handleSelect = (value) => {
    setAddress(value);
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => onLocationSelected(value, latLng))
      .catch((error) => console.error('Error', error));
  };

  const startContent = startAdornment ? (
    <InputIcon position="start">
      {startAdornment}
    </InputIcon>
  ) : null;

  const inputParams = {
    placeholder: 'Search Places ...',
    className: 'location-search-input',
    fullWidth: true,
    startAdornment: startContent,
    disabled: isDisabled,
    ...rest,
  };

  const getInputView = (props) => {
    let customInput;
    switch (variant) {
      case 'simple':
      case 'inline':
        customInput = <StyledInlineInput {...props} />;
        break;
      default:
        customInput = <StyledInput {...props} />;
        break;
    }
    return customInput;
  };

  const isInline = ['simple', 'inline'].includes(variant);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      googleCallbackName="locationCallback"
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
      }) => (
        <FieldWrapper
          flexGrow={flexGrow}
          noMargin={noMargin}
          small={small}
          minheight={minheight}
          variant={variant}
          bgColor={bgColor}
          textColor={textColor}
          borderColor={borderColor}
          borderRadius={borderRadius}
          isDisabled={isDisabled}
        >
          <FlexFlow
            nowrap
            shrink
            grow
            splitSpace="0.5rem"
            align="center"
          >
            <FlexFlow
              column={!isInline}
              nowrap
              grow
              shrink
              align={isInline ? 'center' : 'stretch'}
            >
              {label && label.length > 0 && (
                <InputLabel>{label}</InputLabel>
              )}

              {getInputView({
                ...getInputProps({
                  ...inputParams,
                }),
              })}

              {loading && (
                <AutoCompleteContainer>
                  <AutoCompleteItem>Loading...</AutoCompleteItem>
                </AutoCompleteContainer>
              )}
              {suggestions.length > 0 && (
                <AutoCompleteContainer>
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'active'
                      : '';
                    return (
                      <AutoCompleteItem {...getSuggestionItemProps(suggestion, { className })}>
                        <span>{suggestion.description}</span>
                      </AutoCompleteItem>
                    );
                  })}
                </AutoCompleteContainer>
              )}
            </FlexFlow>

            {helperText && helperText.length > 0 && (
              <Tooltip title={helperText}>
                <div>
                  <FiAlertTriangle />
                </div>
              </Tooltip>
            )}
          </FlexFlow>

        </FieldWrapper>
      )}
    </PlacesAutocomplete>
  );
}

LocationField.defaultProps = {
  label: '',
  address: '',
  flexGrow: 2,
  noMargin: false,
  small: false,
  startAdornment: null,
  minheight: '',
  variant: '',
  bgColor: 'transparent',
  textColor: 'inherit',
  borderColor: 'transparent',
  borderRadius: '0',
  helperText: '',
  isDisabled: false,
};

LocationField.propTypes = {
  flexGrow: PropTypes.number,
  minheight: PropTypes.string,
  label: PropTypes.string,
  address: PropTypes.string,
  noMargin: PropTypes.bool,
  isDisabled: PropTypes.bool,
  startAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  variant: PropTypes.string,
  small: PropTypes.bool,
  onLocationSelected: PropTypes.func.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  helperText: PropTypes.string,
};

export default LocationField;
