import '@splidejs/splide/dist/css/splide.min.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import {
  SwipeWrapper,
  SlideTitle,
  ImgTag,
} from './styles';

function PhotoSlider({
  images,
  height,
  gapSize,
  showTitles,
  useImageTag,
  slidesPerView,
}) {
  const options = {
    type: 'loop',
    cover: !useImageTag,
    height: height || '300px',
    start: 0,
    gap: gapSize,
    perPage: slidesPerView,
    breakpoints: {
      768: {
        perPage: slidesPerView < 2 ? slidesPerView : 2,
      },
      468: {
        perPage: 1,
        gap: 10,
      },
    },
  };

  return (
    <SwipeWrapper slideHeight={height || '300px'}>
      <Splide options={options}>
        {
          images.map(({ url, caption }, i) => (
            <SplideSlide key={i}>
              <ImgTag src={url} slideHeight={height || '300px'} />
              {showTitles && (
                <SlideTitle>
                  {caption}
                </SlideTitle>
              )}
            </SplideSlide>
          ))
        }
      </Splide>
    </SwipeWrapper>
  );
}

PhotoSlider.defaultProps = {
  height: '300px',
  gapSize: 20,
  showTitles: false,
  useImageTag: false,
  slidesPerView: 3,
};

PhotoSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape(),
  ).isRequired,
  height: PropTypes.string,
  gapSize: PropTypes.number,
  slidesPerView: PropTypes.number,
  showTitles: PropTypes.bool,
  useImageTag: PropTypes.bool,
};

export default PhotoSlider;
