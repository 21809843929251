import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiSlider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import colors from '../../constants/colors';
import { SliderWrapper } from './styles';

const Slider = styled(MuiSlider)({
  boxSizing: 'border-box',
  padding: '0.5em 2em 0.5em 0.5em',
  color: colors.grey200,
  height: 8,
  '& .MuiSlider-track': {
    backgroundColor: colors.groupizeBrand,
  },
  '& .MuiSlider-thumb': {
    height: '1,25rem',
    width: '1,25rem',
    backgroundColor: '#fff',
    border: '0.25rem solid currentColor',
    borderColor: colors.groupizeBrand,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: colors.groupizeBrand,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

function SliderField({
  defaultValue,
  onChange,
  id,
  label,
  className,
  min,
  max,
  step,
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <SliderWrapper className={className}>
      <Typography id={id}>
        {label}
      </Typography>
      <Slider
        valueLabelDisplay="auto"
        value={value}
        min={min}
        max={max}
        step={step}
        aria-labelledby={id}
        onChange={handleChange}
      />
    </SliderWrapper>
  );
}

SliderField.defaultProps = {
  label: '',
  className: '',
  min: 0,
  max: 10,
  step: 1,
  defaultValue: 0,
};

SliderField.propTypes = {
  id: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  step: PropTypes.number,
};

export default SliderField;
