import styled from 'styled-components';
import { grey } from '@mui/material/colors';

export const DataWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0.5em;
`;

export const DataTitle = styled.div`
  color: ${grey[600]};
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.4;
  margin-right: 1em;
`;

export const DataValue = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
  color: ${grey[600]};

  svg {
    color: ${grey[400]};
  }
`;
