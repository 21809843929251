export default {
  groupizeBrand: '#73BD5D',
  white: '#ffffff',
  grey50: '#fafafa',
  grey100: '#f5f5f5',
  grey200: '#eeeeee',
  grey300: '#e0e0e0',
  grey400: '#bdbdbd',
  grey500: '#9e9e9e',
  grey600: '#757575',
  grey700: '#616161',
  grey800: '#424242',
  grey900: '#212121',
  lightBlue50: '#e1f5fe',
  lightBlue100: '#b3e5fc',
  lightBlue200: '#81d4fa',
  lightBlue300: '#4fc3f7',
  lightBlue400: '#29b6f6',
  lightBlue500: '#03a9f4',
  lightBlue600: '#039be5',
  lightBlue700: '#0288d1',
  lightBlue800: '#0277bd',
  lightBlue900: '#01579b',
  lightBlueA100: '#80d8ff',
  lightBlueA200: '#40c4ff',
  lightBlueA400: '#00b0ff',
  lightBlueA700: '#0091ea',
  blueGrey50: '#eceff1',
  blueGrey100: '#cfd8dc',
  blueGrey200: '#b0bec5',
  blueGrey300: '#90a4ae',
  blueGrey400: '#78909c',
  blueGrey500: '#607d8b',
  blueGrey600: '#546e7a',
  blueGrey700: '#455a64',
  blueGrey800: '#37474f',
  blueGrey900: '#263238',
  orange600: '#fb8c00',
  green500: '#4caf50',
  blue600: '#1e88e5',
  brandPurple: '#a520d1',
  pink50: '#FCE4EC',
  pink100: '#F8BBD0',
  pink200: '#F48FB1',
  pink300: '#F06292',
  pink400: '#EC407A',
  pink500: '#E91E63',
  pink600: '#D81B60',
  pink700: '#C2185B',
  pink800: '#AD1457',
  pink900: '#880E4F',
  pinkA100: '#FF80AB',
  pinkA200: '#FF4081',
  pinkA400: '#F50057',
  pinkA700: '#C51162',
  red50: '#ffebee',
  red100: '#ffcdd2',
  red200: '#ef9a9a',
  red300: '#e57373',
  red400: '#ef5350',
  red500: '#f44336',
  red600: '#e53935',
  red700: '#d32f2f',
  red800: '#c62828',
  red900: '#b71c1c',
  redA100: '#ff8a80',
  redA200: '#ff5252',
  redA400: '#ff1744',
  redA700: '#d50000',
  darkBlue: '#1f2532',
  flatYellow: '#f1c40f',

  basic100: '#FFFFFF',
  basic200: '#F8F8F8',
  basic300: '#E0E2E0',
  basic400: '#C8CBC6',
  basic500: '#ADB1AA',
  basic600: '#949A91',
  basic700: '#575A53',
  basic800: '#616A5D',
  basic900: '#475242',
  basic1000: '#2F3B29',
  basic1100: '#16230F',

  brand100: '#ECF6E9',
  brand200: '#D9EDD3',
  brand300: '#C0E1B5',
  brand400: '#A5D597',
  brand500: '#8DCA7B',
  brand600: '#73BD5D',
  brand700: '#59B03F',
  brand800: '#3A8D21',
  brand900: '#1B6A02',

  primary100: '#ECF6E9',
  primary200: '#D9EDD3',
  primary300: '#C0E1B5',
  primary400: '#A5D597',
  primary500: '#8DCA7B',
  primary600: '#73BD5D',
  primary700: '#59B03F',
  primary800: '#3A8D21',
  primary900: '#1B6A02',

  success100: '#F0FFFA',
  success200: '#C8F2E3',
  success300: '#9FE3CC',
  success400: '#77D6B6',
  success500: '#4BC69D',
  success600: '#21B886',
  success700: '#01A870',
  success800: '#018C5E',
  success900: '#016E4A',

  info100: '#F2F8FF',
  info200: '#C7E2FF',
  info300: '#94CBFF',
  info400: '#42AAFF',
  info500: '#0095FF',
  info600: '#006FD6',
  info700: '#0057C2',
  info800: '#0041A8',
  info900: '#002885',

  warning100: '#FFFDF2',
  warning200: '#FFF1C2',
  warning300: '#FFE59E',
  warning400: '#FFC94D',
  warning500: '#FFAA00',
  warning600: '#DB8B00',
  warning700: '#B86E00',
  warning800: '#945400',
  warning900: '#703C00',

  danger100: '#FFF2F2',
  danger200: '#FFD6D9',
  danger300: '#FFA8B4',
  danger400: '#FF708D',
  danger500: '#FF3D71',
  danger600: '#DB2C66',
  danger700: '#7B8277',
  danger800: '#94124E',
  danger900: '#700940',

  textColorHint: '#7B8277',
};
