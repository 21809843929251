import * as React from 'react';
import styled, { css } from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import colors from '../constants/colors';

export const AccordionWrapper = MuiStyled((props) => (<MuiAccordion {...props} />))({
  backgroundColor: colors.white,
  boxShadow: 'none',
  color: colors.grey800,
  '&:before': {
    backgroundColor: colors.grey100,
  },
});

export const AccordionTitle = MuiStyled((props) => (<MuiAccordionSummary {...props} />))(
  (props) => ({
    backgroundColor: props.config.bgColor || colors.grey50,
    border: 'none',
    borderLeft: '3px solid',
    borderColor: props.config.borderColor || colors.groupizeBrand,
    color: props.config.color || colors.blue600,
    fontSize: '1.2em',
    padding: '0 1em',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
    },
  }),
);

export const AccordionTitleWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 auto;
  align-items: center;
  justify-content: space-between;

  ${(props) => props.showIconDivider && css`
    border-right: 1px solid;
    border-color: inherit;
  `}
`;

export const AccordionContent = MuiStyled(MuiAccordionDetails)((props) => ({
  backgroundColor: props.config.bgColor || colors.white,
  borderLeft: '3px solid transparent',
  color: props.config.color || colors.grey800,
  padding: '2em 1em',
}));
