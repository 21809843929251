import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {
  FieldWrapper,
  RadioIcon,
  RadioCheckedIcon,
} from '../../helpers/styles';

// Inspired by blueprintjs
function StyledRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<RadioCheckedIcon />}
      icon={<RadioIcon />}
      {...props}
    />
  );
}

function RadioButtons({
  value: defaultValue,
  label: fieldLabel,
  onChange,
  name,
  items,
  variant,
  flexGrow,
  noWrapper,
  isDisabled,
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    const thisValue = event.target.value;
    onChange(thisValue);
    setValue(thisValue);
  };

  return (
    <FieldWrapper
      noMargin
      noWrapper={noWrapper}
      flexGrow={flexGrow}
      variant={variant}
    >
      {fieldLabel && fieldLabel.length > 0 && (
        <FormLabel>{fieldLabel}</FormLabel>
      )}
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {items.map(({ label, value: itemValue }) => (
          <FormControlLabel
            key={itemValue}
            value={itemValue}
            control={<StyledRadio disabled={isDisabled} />}
            label={label}
          />
        ))}
      </RadioGroup>
    </FieldWrapper>
  );
}

RadioButtons.defaultProps = {
  label: '',
  value: '',
  variant: '',
  noWrapper: false,
  flexGrow: true,
  isDisabled: false,
};

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  flexGrow: PropTypes.bool,
  noWrapper: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default RadioButtons;
