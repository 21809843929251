import React from 'react';
import PropTypes from 'prop-types';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { FlexFlow } from '../helpers/DisplayHelpers';
import colors from '../constants/colors';

const Tabs = styled(MuiTabs)({
  borderBottom: `1px solid ${colors.grey200}`,
  flex: '1 0 auto',
  '& .MuiTabs-indicator': {
    backgroundColor: colors.groupizeBrand,
  },
});

const Tab = styled((props) => <MuiTab disableRipple {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: 72,
    position: 'relative',
    textTransform: 'initial',
    transition: 'all 0.3s ease',
    '&.hasNotification': {
      paddingLeft: '25px',
      '&:before': {
        content: "''",
        backgroundColor: colors.groupizeBrand,
        borderRadius: '500rem',
        height: '10px',
        width: '10px',
        position: 'absolute',
        left: '5px',
        top: 'calc(50% - 5px)',
      },
    },
    '&:hover': {
      color: colors.groupizeBrand,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: colors.groupizeBrand,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      color: colors.groupizeBrand,
    },
  }),
);

function SimpleTabs({
  items,
  style,
  children,
  ...rest
}) {
  return (
    <FlexFlow nowrap>
      <Tabs {...rest}>
        {items.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            className={tab.notification ? 'hasNotification' : ''}
          />
        ))}
      </Tabs>

      {children}
    </FlexFlow>
  );
}

SimpleTabs.defaultProps = {
  style: 'default',
  children: null,
};

SimpleTabs.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      notification: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.string,
};

export default SimpleTabs;
