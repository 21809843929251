import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import * as ClickAwayListener from '@mui/base/ClickAwayListener';

const DialogWrapper = styled.div`
  position: absolute;
  top: 100%;
  z-index: 9;
`;

function PickerDialog({ value, onClickAway, onChange }) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <DialogWrapper>
        <ChromePicker color={value} onChange={onChange} />
      </DialogWrapper>
    </ClickAwayListener>
  );
}

PickerDialog.defaultProps = {
  value: '',
};

PickerDialog.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClickAway: PropTypes.func.isRequired,
};

export default PickerDialog;
