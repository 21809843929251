import styled, { css } from 'styled-components';
import colors from '../constants/colors';

export const HeroWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  align-items: stretch;
  justify-content: center;

  background-color: ${(props) => props.bgColor || colors.grey500};
  color: ${(props) => props.textColor || colors.white};
  min-height: ${(props) => (props.height ? `${props.height}px` : '300px')};

  padding: 2em;
  position: relative;

  ${(props) => props.bgImg && css`
    background-image: url("${props.bgImg}");
    background-size: cover;
    background-position: center;
  `}
`;

export const HeroBody = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  > * {
    z-index: 1;
  }

  ${(props) => props.contentPosition == 'center' && css`
    justify-content: center;
  `}

  ${(props) => props.contentPosition == 'right' && css`
    flex-flow: row-reverse nowrap;
    justify-content: stretch;
  `}

  @media (max-width: 992px) {
    flex-flow: column nowrap;
    flex: 1 0 auto;
    padding: 2em 1em;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const OpacityWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, ${(props) => `${props.bgOverlayOpacity}`});
`;

export const HeroContentArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  flex-basis: 50%;

  margin: 0 1.5em;

  ${(props) => props.isShown === false && css`
    display: none;
`};

  ${(props) => props.contentPosition == 'center' && css`
    align-items: center;
  `}

  @media (max-width: 992px) {
    justify-content: center;
    margin: 0;
  }
`;

export const HeroTitle = styled.div`
  color: inherit;
  font-size: 2.2em;
  font-weight: 300;
  margin: 0 0 1rem;

  p {
    margin: 0;
  }
`;

export const HeroDescription = styled.div`
  color: inherit;
  font-size: 1em;
  font-weight: 100;
  line-height: 1.4em;
  margin: 0 0 1em;

  p {
    margin: 0;
  }
`;

export const HeroDate = styled.p`
  color: inherit;
  font-size: 1.2em;
  font-weight: 200;
  margin: 0 0 0.5rem;
`;

export const HeroAddress = styled.p`
  color: inherit;
  font-size: 0.8em;
  font-weight: 300;
  margin: 0 0 1rem;
`;

export const HeroText = styled.p`
  color: inherit;
  font-size: 1em;
  font-weight: 400;
  margin: 0 0 1rem;
`;

export const HeroBy = styled.p`
  color: inherit;
  font-size: 0.7em;
  font-weight: 200;
  margin: 0 0 0.5em;
`;
