import styled, { keyframes } from 'styled-components';
import * as animations from 'react-animations';

const getAnimationKeyframes = (animation) => keyframes`${animations[animation]}`;

export const AnimationDiv = styled.div`
  animation-iteration-count: ${(props) => props.iterations || '1'};
  animation-delay: ${(props) => props.delay || '0'};
  animation-duration: ${(props) => props.duration || '1s'};
  animation-name: ${(props) => getAnimationKeyframes(props.animation || 'pulse')}
`;
