import React from 'react';
import PropTypes from 'prop-types';

function IconCheckbox({
  id, index, checked, onClick,
}) {
  return (
    <div className="ui checkbox icon">
      <input
        id={id}
        type="checkbox"
        onClick={() => onClick(index)}
        checked={checked}
        tabIndex={index}
        className="search-filter"
        readOnly
      />
      <label htmlFor={id}>
        <span>{index}</span>
        <i className="icon star" />
        <i className="icon star outline" />
      </label>
    </div>
  );
}

IconCheckbox.defaultProps = {
  checked: false,
};

IconCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

export default IconCheckbox;
