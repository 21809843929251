import styled, { css, keyframes } from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import colors from '../constants/colors';

const rotate = keyframes`
  0% {
    transform: rotate(60deg);
    color: ${colors.grey800};
  }

  10% {
    transform: rotate(0deg);
    color: ${colors.groupizeBrand};
  }

  100% {
    transform: rotate(0deg);
    color: ${colors.groupizeBrand};
  }
`;

const floatingAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${green[400]};
    background-color: ${green[200]};
  }
  15% {
    box-shadow: 0 0 0 15px transparent;
    background-color: ${colors.white};
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
    background-color: ${colors.white};
  }
`;

export const ToggleScreenBtn = styled(IconButton)`
  padding: 6px !important;

  svg {
    font-size: 1.2em !important;
  }
`;

export const SettingsPanelWrapper = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-flow: row nowrap;

  border-radius: 50% 0 0 50%;
  padding: 0.5em;
  position: absolute;
  top: 1em;
  right: 0;

  animation: ${floatingAnimation} 4s linear infinite;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(01,0,0,0.6);

  z-index: 998;

  &:hover {
    animation: none;
    transform: translateX(0px);

    ${ToggleScreenBtn} {
      animation: none;
      transform: rotate(0deg);
    }
  }

  ${ToggleScreenBtn} {
    animation: ${rotate} 4s linear infinite;
  }
`;

export const GroupizeBtn = styled(Button)`
  background-color: ${colors.groupizeBrand} !important;
  color: white !important;
`;

export const SidePanel = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
`;

export const SideMainPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.grey200};

  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: -350px;
  z-index: 2;

  box-sizing: border-box;
  transition: right 0.3s ease;
  width: 350px;
  font-size: 1rem;

  ${(props) => props.open && css`
    right: 0;
  `}
`;

export const SidePanelHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  justify-content: center;

  box-sizing: border-box;
  height: 60px;
  padding: 0.5em 1em;

  background-color: ${colors.grey900};
  color: ${colors.grey50};
  font-size: 0.9em;
  text-transform: uppercase;
`;

export const SideMainPanelContent = styled.div`
  background-color: ${colors.grey800};
  color: ${colors.grey50};
  padding: 0.5em 1em;
  overflow: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
`;

export const SideInnerPanelContent = styled(SideMainPanelContent)`
  background-color: ${colors.grey50};
  color: ${colors.grey800};
`;

export const SideInnerPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  box-sizing: border-box;
  padding: 0;
  transition: right 0.3s ease;
  width: 300px;
  z-index: -1;

  ${(props) => props.open && css`
     left: auto;
     right: 100%;
  `}
`;

export const SidePanelTitle = styled(SidePanelHeader)`
  background-color: ${colors.grey100};
  color: ${colors.grey600};
`;

export const SideActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  font-size: 1.2em;

  .icon-btn {
    color: ${colors.grey50};
  }

  .small-icon {
    height: 0.7em;
    width: 0.7em;
  }
`;
