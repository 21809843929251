import styled from 'styled-components';
import { grey } from '@mui/material/colors';
import colors from '../constants/colors';

export const PanelWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  margin-bottom: 1em;
  padding: 1em;

  background-color: ${grey[50]};
  border-left: 3px solid ${colors.groupizeBrand};
`;
