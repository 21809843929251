import React from 'react';
import PropTypes from 'prop-types';
import {
  DivideWrapper,
  StartLine,
  EndLine,
  Text,
} from './styles';

function Divider({ label, type }) {
  return (
    <DivideWrapper type={type} hasLabel={label && label.length > 0}>
      <StartLine />
      {label && label.length > 0 && (
        <Text>{label}</Text>
      )}
      <EndLine />
    </DivideWrapper>
  );
}

Divider.defaultProps = {
  label: '',
  type: 'horizontal',
};

Divider.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['horizontal', 'vertical']),
};

export default Divider;
