import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

function AlertMessage({
  open,
  handleClose,
  variant,
  message,
  duration,
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

AlertMessage.defaultProps = {
  variant: 'info',
  message: '',
  duration: 5000,
};

AlertMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  message: PropTypes.string,
  duration: PropTypes.number,
};

export default AlertMessage;
