import styled, { css } from 'styled-components';
import {
  grey,
  blueGrey,
  green,
  purple,
} from '@mui/material/colors';

export const InlineLabel = styled.p`
  margin: 0 1em 0 0;
`;

export const MessageAuthor = styled.p`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;

  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.4;
  margin: 0 0 0.5em;
`;

export const MessageDate = styled.span`
  color: ${grey[500]};
  font-size: 0.6em;
  line-height: 1.2;
  margin-left: auto;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  align-items: flex-start;
  margin: 1em 0;
`;

export const MessageContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  border: 1px solid ${grey[200]};
  padding: 1em;
  position: relative;
`;

export const MessageContentTitle = styled.p`
  font-size: 1em;
  font-weight: 600;
  line-height: 1.4;
  margin: 0 0 0.5em;
`;

export const MessageContentText = styled.div`
  font-size: 1em;
  font-weight: 300;
  line-height: 1.4;
  margin: 0 0 0.5em;
`;

export const LabelsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  position: absolute;
  top: 0.75em;
  right: 0.75em;
`;

export const LabelItem = styled.span`
  background-color: ${grey[200]};
  border-radius: 6px;

  color: ${grey[800]};
  &:hover {
    color: white;
    background-color: #73BD5D;
  }

  ${(props) => props.label === 'property' && css`
    background-color: ${green[100]};

    &:hover {
      background-color: ${green[600]};
    }
  `}

  ${(props) => props.label === 'internal' && css`
    background-color: ${purple[100]};

    &:hover {
      background-color: ${purple[600]};
    }
  `}

  ${(props) => props.label === 'other' && css`
    background-color: ${blueGrey[100]};

    &:hover {
      background-color: ${blueGrey[600]};
    }
  `}

  font-size: 0.6em;
  text-transform: uppercase;

  padding: 0.2rem 0.5rem;
  margin: 0.25em;
  transition: all 0.3s ease;
`;
