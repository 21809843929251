import React from 'react';
import PropTypes from 'prop-types';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import grey from '@mui/material/colors/grey';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { styled } from '@mui/material/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'grc-popup',
});

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: grey[50],
    color: grey[600],
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: '150px',
  },
}));

function Popup({
  title,
  children,
  ...rest
}) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Tooltip title={title} {...rest}>
        {children}
      </Tooltip>
    </StylesProvider>
  );
}

Popup.defaultProps = {
  title: '',
};

Popup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
};

export default Popup;
