import styled, { css } from 'styled-components';
import colors from '../constants/colors';

export const MediaWrapper = styled.div`
  display: flex;
  align-self: stretch;
  flex: 0 1 auto;
  flex-basis: 450px;

  min-height: 300px;
  margin: 0 1.5em;
  position: relative;

  ${(props) => props.mediaBgColor && css`
    background-color: ${props.mediaBgColor};
    border: 1px solid ${props.mediaBgColor};
    box-shadow: 0 15px 30px -15px ${props.mediaBgColor};
    color: ${props.mediaBgColor};
  `};

  border-radius: 6px;
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    flex-basis: 300px;
    margin: 0 0 2em;
    order: -1;
  }
`;

export const ImageCover = styled.div`
  display: flex;
  flex: 1 0 auto;

  ${(props) => props.imageUrl && css`
    background-image: url("${props.imageUrl}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const VideoWrapper = styled.div`
  background-color: ${colors.red100};

  display: flex;
  align-self: stretch;
  min-width: 450px;
  margin-right: 3em;

  ${(props) => props.videoUrl && `
    background-image: url("${props.videoUrl}");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;
