import React from 'react';
import PropTypes from 'prop-types';
import VideoBackground from '../VideoBackground/VideoBackground';
import MediaContent from '../MediaContent/MediaContent';
import HeroContent from './HeroContent';
import {
  HeroBody,
  HeroWrapper,
  OpacityWrapper,
} from './styles';

function HeroSection(props) {
  const {
    bgColor,
    bgImage,
    bgVideo,
    showBgImage,
    bgOverlayOpacity,
    textColor,
    contentPosition,
    mediaImageUrl,
    mediaVideoUrl,
    showMediaContent,
    mediaBgColor,
    heroType,
    bgHeight,
    children,
  } = props;

  return (
    <HeroWrapper
      height={bgHeight}
      bgColor={bgColor}
      bgImg={showBgImage ? bgImage : ''}
      textColor={textColor}
      className="HeroWrapper"
    >
      {bgVideo && showBgImage && (
        <VideoBackground src={bgVideo} />
      )}

      {showBgImage && (
        <OpacityWrapper bgOverlayOpacity={bgOverlayOpacity} />
      )}

      <HeroBody contentPosition={contentPosition}>
        <HeroContent {...props} />

        {(contentPosition !== 'center')
          && showMediaContent
          && (['media'].includes(heroType)) && (
            <MediaContent
              mediaBgColor={mediaBgColor}
              imageUrl={mediaImageUrl}
              videoUrl={mediaVideoUrl}
            />
          )}
      </HeroBody>
      {children}
    </HeroWrapper>
  );
}

HeroSection.defaultProps = {
  type: 'default',
  bgColor: '#fafafa',
  bgImage: '',
  bgVideo: '',
  height: 300,
  bgOverlayOpacity: 0.4,
  textColor: '#000000',
  contentPosition: 'left',
  mediaImageUrl: '',
  mediaVideoUrl: '',
  showBgImage: false,
  showMediaContent: false,
  mediaBgColor: '#eeeeee',
};

HeroSection.propTypes = {
  showBgImage: PropTypes.bool,
  height: PropTypes.number,
  type: PropTypes.string,
  bgColor: PropTypes.string,
  bgImage: PropTypes.string,
  bgVideo: PropTypes.string,
  mediaBgColor: PropTypes.string,
  showMediaContent: PropTypes.bool,
  mediaImageUrl: PropTypes.string,
  mediaVideoUrl: PropTypes.string,
  bgOverlayOpacity: PropTypes.number,
  textColor: PropTypes.string,
  contentPosition: PropTypes.oneOf(['left', 'center', 'right']),
};

export default HeroSection;
