import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import {
  AccordionWrapper,
  AccordionTitle,
  AccordionContent,
  AccordionTitleWrapper,
} from './styles';

function Accordion({
  config: {
    titleIcon,
    titleIconColor,
    titleText,
    titleColor,
    titleBGColor,
    titleBorderColor,
    contentColor,
    contentBGColor,
    showIconDivider,
    showDeleteIcon,
    deleteIcon,
    deleteAction,
    isOpen,
  },
  children,
}) {
  return (
    <AccordionWrapper defaultExpanded={isOpen}>
      <AccordionTitle
        expandIcon={titleIcon}
        config={{
          color: titleColor || null,
          borderColor: titleBorderColor || null,
          bgColor: titleBGColor || null,
          iconColor: titleIconColor || null,
        }}
      >
        <AccordionTitleWrapper showIconDivider={showIconDivider}>
          {titleText}
          {showDeleteIcon && (
            <IconButton
              key="close"
              aria-label="Close"
              onClick={deleteAction}
            >
              {deleteIcon}
            </IconButton>
          )}
        </AccordionTitleWrapper>
      </AccordionTitle>
      <AccordionContent
        config={{
          color: contentColor || null,
          bgColor: contentBGColor || null,
        }}
      >
        {children}
      </AccordionContent>
    </AccordionWrapper>
  );
}

Accordion.defaultProps = {
  config: {
    titleIcon: null,
    titleIconColor: '#73BD5D',
    titleText: '',
    titleColor: '#1e88e5',
    titleBGColor: '#fafafa',
    titleBorderColor: '#73BD5D',
    showIconDivider: false,
    showDeleteIcon: false,
    isOpen: false,
    deleteIcon: null,
    deleteAction: () => true,
    contentBGColor: 'inherit',
    contentColor: '#424242',
  },
};

Accordion.propTypes = {
  config: PropTypes.shape({
    titleIcon: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    titleIconColor: PropTypes.string,
    titleText: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string,
    ]),
    titleColor: PropTypes.string,
    titleBGColor: PropTypes.string,
    titleBorderColor: PropTypes.string,
    contentColor: PropTypes.string,
    contentBGColor: PropTypes.string,
    showDeleteIcon: PropTypes.bool,
    showIconDivider: PropTypes.bool,
    isOpen: PropTypes.bool,
    deleteIcon: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    deleteAction: PropTypes.func,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Accordion;
