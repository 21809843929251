/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Tooltip } from '@mui/material';
import { FiAlertTriangle } from 'react-icons/fi';
import { FlexFlow } from '../../helpers/DisplayHelpers';
import { useDebouncedEffect } from '../../../Hooks/useDebouncedEffect';
import {
  FieldWrapper,
  StyledInput,
  StyledInlineInput,
  InputIcon,
  Required,
} from '../../helpers/styles';

const textFieldData = {
  className: 'form-field',
  fullWidth: true,
};

function NumberField({
  id,
  label,
  min,
  max,
  small,
  defaultNum,
  variant,
  nomargin,
  flexGrow,
  onChanged,
  priceCent,
  minheight,
  startAdornment,
  bgColor,
  textColor,
  borderColor,
  borderRadius,
  helperText,
  isRequired,
  debounce,
  ...rest
}) {
  const [value, setValue] = useState(defaultNum);

  useEffect(() => {
    const newValue = (priceCent && defaultNum !== '') ? Math.round(defaultNum) / 100 : defaultNum;
    setValue(newValue);
  }, [defaultNum]);

  const handleChange = (num) => {
    let thisNum = 0;

    if (num !== '') {
      thisNum = +num;

      if (max !== null) {
        thisNum = thisNum >= max ? max : thisNum;
      }
      if (min !== null) {
        thisNum = thisNum <= min ? min : thisNum;
      }
    }
    setValue(thisNum);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const startContent = startAdornment ? (
    <InputIcon position="start">
      {startAdornment}
    </InputIcon>
  ) : null;

  const handlePriceValue = (newValue) => ((newValue === '') ? newValue : +newValue * 100);
  const priceValue = (newValue) => ((newValue === '') ? newValue : +newValue);
  const inputValue = priceCent ? priceValue(value) : value;

  useDebouncedEffect(() => {
    const v = priceCent
      ? handlePriceValue(value)
      : value;
    onChanged(v);
  }, debounce, [value]);

  const onChangeHandler = (e) => {
    handleChange(e.target.value);
  };

  const inputParams = {
    id,
    placeholder: '#',
    type: 'number',
    value: inputValue,
    onChange: onChangeHandler,
    onFocus: handleFocus,
    startAdornment: startContent,
    inputProps: {
      min,
      max,
    },
    ...textFieldData,
    ...rest,
  };

  let customInput;
  switch (variant) {
    case 'simple':
    case 'inline':
      customInput = <StyledInlineInput {...inputParams} />;
      break;
    default:
      customInput = <StyledInput {...inputParams} />;
      break;
  }

  const isInline = ['simple', 'inline'].includes(variant);

  return (
    <FieldWrapper
      small={small}
      variant={variant}
      nomargin={nomargin}
      flexGrow={flexGrow}
      minheight={minheight}
      bgColor={bgColor}
      textColor={textColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
    >
      <FlexFlow
        nowrap
        shrink
        grow
        splitSpace="0.5rem"
        align="center"
      >
        <FlexFlow
          column={!isInline}
          nowrap
          grow
          shrink
          align={isInline ? 'center' : 'stretch'}
        >
          {label && label.length > 0 && (
            <InputLabel>
              {isRequired && <Required />}
              {label}
            </InputLabel>
          )}
          {customInput}
        </FlexFlow>

        {helperText && helperText.length > 0 && (
          <Tooltip title={helperText}>
            <div>
              <FiAlertTriangle />
            </div>
          </Tooltip>
        )}
      </FlexFlow>
    </FieldWrapper>
  );
}

NumberField.defaultProps = {
  label: '',
  defaultNum: 0,
  min: null,
  max: null,
  variant: '',
  nomargin: false,
  flexGrow: true,
  small: false,
  isRequired: false,
  priceCent: false,
  minheight: '0',
  startAdornment: null,
  bgColor: 'transparent',
  textColor: 'inherit',
  borderColor: 'transparent',
  borderRadius: '0',
  helperText: '',
  debounce: 300,
  onChanged: () => {},
};

NumberField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultNum: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  min: PropTypes.number,
  max: PropTypes.number,
  startAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  small: PropTypes.bool,
  isRequired: PropTypes.bool,
  variant: PropTypes.string,
  nomargin: PropTypes.bool,
  flexGrow: PropTypes.bool,
  priceCent: PropTypes.bool,
  onChanged: PropTypes.func,
  minheight: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  helperText: PropTypes.string,
  debounce: PropTypes.number,
};

export default NumberField;
