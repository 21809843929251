import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import { styled as MuiStyled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import colors from '../../constants/colors';

export const FilePickerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  ${(props) => props.hasWrapper && css`
    background-color: ${colors.white};
    border: 1px solid ${colors.grey200};
    border-radius: 5px;
    padding: 1em;
  `}

  > label {
    margin-bottom: 0.5em;
  }
`;

export const FilePickerContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;

  ${(props) => (props.variant === 'inline') && css`
    flex-direction: row;
  `}
`;

export const GroupizeBtn = MuiStyled(Button)({
  color: colors.groupizeBrand,
  borderColor: colors.groupizeBrand,
  marginLeft: 'auto',
});

export const RemoveBtn = styled(Button)`
  background-color: ${colors.red400} !important;
  color: white !important;
  margin-left: 1em !important;
`;

export const ActionBtns = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;

  ${(props) => props.variant === 'inline' && css`
    margin-left: auto;
  `}

  ${(props) => props.variant === 'left' && css`
    align-self: flex-start;
  `}
`;

export const FileList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  align-self: stretch;
  margin-right: 1em;
  margin-bottom: 1em;
  overflow: hidden;
`;

export const FileItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  align-items: center;
  border-bottom: 1px solid ${grey[200]};
  padding: 0.5em 1em;

  > svg {
    margin: 0 0.5em;
  }
`;

export const FileName = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FileActions = styled.div`
  margin-left: auto;
`;

export const HelperText = styled.p`
  color: ${(props) => props.color || colors.grey600};
  font-size: 0.8em;
  margin: 0.5em 0 0;
  word-wrap: break-word;

  > span {
    font-weight: 600;
  }
`;
