import styled from 'styled-components';

export const SwipeWrapper = styled.div`
  position: relative;
  height: ${(props) => props.slideHeight || '300px'};
  width: 100%;
`;

export const SlideTitle = styled.p`
  align-self: flex-start;

  background-color: #000;
  color: white;

  margin: 1em;
  padding: 0.5em 1em;
  position: relative;
  text-transform: capitalize;
`;

export const ImgTag = styled.img`
  position: relative;
  max-height: ${(props) => props.slideHeight || '300px'};
  width: auto !important;
`;
