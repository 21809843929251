import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap,
  MarkerClusterer,
} from '@react-google-maps/api';
import { MapContainer } from './styles';
import GMapMarker from './GMapMarker';

function GMapArea({
  markers,
  poi,
}) {
  const [isLoaded, setLoaded] = useState(false);
  const [map, setMap] = useState();
  const [mapZoom, setMapZoom] = useState(10);
  const [mapCenter, setMapCenter] = useState();
  const [activeMarkerId, setActiveMarker] = useState(0);

  const onIdleZoom = () => {
    if (isLoaded) return;

    const bounds = new window.google.maps.LatLngBounds();

    // Add POI location
    const poiLocation = new window.google.maps.LatLng(poi.position.lat, poi.position.lng);
    bounds.extend(poiLocation);

    // Add marker locations (if any)
    markers.map((marker) => {
      const eventLocation = new window.google.maps.LatLng(
        marker.position.lat,
        marker.position.lng,
      );
      return bounds.extend(eventLocation);
    });

    map.fitBounds(bounds);
    const currentZoom = map.getZoom();

    if (currentZoom > 5) {
      setMapZoom(currentZoom);
    }

    if (currentZoom < 5 || currentZoom > 16) {
      setMapZoom(10);
    }

    setLoaded(true);
  };

  return (
    <MapContainer>
      <GoogleMap
        mapContainerClassName="map"
        defaultZoom={8}
        defaultCenter={poi.position}
        center={mapCenter}
        zoom={mapZoom}
        onLoad={(m) => { setMap(m); }}
        onIdle={onIdleZoom}
        onClick={() => { setActiveMarker(null); }}
      >
        <GMapMarker key="poi" {...poi} />

        {markers.length > 0 && (
          <MarkerClusterer averageCenter enableRetinaIcons gridSize={30}>
            {
              (clusterer) => markers.map(({
                id,
                position,
                icon,
                content,
                callback,
              }) => {
                const markerOptions = {
                  isActive: (activeMarkerId === id),
                  callback: (() => {
                    setActiveMarker(id);
                    setMapCenter(position);
                    if (callback instanceof Function) {
                      callback();
                    }
                  }),
                  position,
                  icon,
                  defaultAnimation: 2,
                  markerContent: content,
                  handleCloseMarker: (() => {
                    setActiveMarker(null);
                  }),
                };
                return <GMapMarker key={id} id={id} activeId={activeMarkerId} {...markerOptions} />;
              })
            }
          </MarkerClusterer>
        )}

      </GoogleMap>
    </MapContainer>
  );
}

GMapArea.defaultProps = {
  markers: [],
  poi: {
    icon: 'https://s3.amazonaws.com/groupizereg-production/icons/poi_map_icon_m.png',
  },
};

GMapArea.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape()),
  poi: PropTypes.shape({
    icon: PropTypes.string,
    position: PropTypes.shape().isRequired,
  }),
};

export default GMapArea;
