import styled from 'styled-components';
import colors from '../../constants/colors';

export const ColorPickerArea = styled.div`
  position: relative;

  .form-field > div {
    box-sizing: border-box;
  }
`;

export const SampleBox = styled.div`
  background-color: ${(props) => props.color || colors.grey200};
  border-radius: 0.2em;

  display: block;
  width: 1.4em;
  height: 1.4em;
`;
