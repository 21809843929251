import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog';
import SelectField from '../Form/SelectField/SelectField';

function ChangeLabelForm({
  openModal,
  onChangeLabel,
  onClose,
  value,
  options,
}) {
  const [messageLabel, setMessageLabel] = useState(value);

  const data = {
    open: openModal,
    title: 'Update Label',
    maxWidth: 'sm',
    showActionBtns: true,
    cancelBtnCopy: 'Cancel',
    confirmBtnCopy: 'Update',
  };

  return (
    <ConfirmationDialog
      {...data}
      handleCancel={onClose}
      handleConfirm={() => onChangeLabel(messageLabel)}
    >
      <SelectField
        nomargin
        id="select_label"
        name="label"
        value={messageLabel}
        items={options.map((c) => ({ value: c, label: c }))}
        onChange={(e) => setMessageLabel(e.target.value)}
      />
    </ConfirmationDialog>
  );
}

ChangeLabelForm.defaultProps = {
  value: '',
  options: [],
};

ChangeLabelForm.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onChangeLabel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default ChangeLabelForm;
