import { css } from 'styled-components';
import colors from './colors';

export const defaultTextStyle = css`
  font-family: 'Open Sans', 'helvetica', 'sans-serif';
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0;
  text-align: left;
`;

export const h1 = css`
  ${defaultTextStyle};
  font-size: 36px;
  font-weight: 700;
  color: ${colors.basic900};
`;

export const h2 = css`
  ${defaultTextStyle};
  font-size: 32px;
  font-weight: 700;
  color: ${colors.basic900};
`;

export const h3 = css`
  ${defaultTextStyle};
  font-size: 30px;
  font-weight: 700;
  color: ${colors.basic900};
`;

export const h4 = css`
  ${defaultTextStyle};
  font-size: 26px;
  font-weight: 700;
  color: ${colors.basic900};
`;

export const h5 = css`
  ${defaultTextStyle};
  font-size: 22px;
  font-weight: 600;
  color: ${colors.basic900};
`;

export const h6 = css`
  ${defaultTextStyle};
  font-size: 18px;
  font-weight: 700;
  color: ${colors.basic900};
`;

export const subtitle = css`
  ${defaultTextStyle};
  font-size: 15px;
  font-weight: 600;
  color: ${colors.basic900};
  letter-spacing: 0.15px;
`;

export const subtitle2 = css`
  ${defaultTextStyle};
  font-size: 13px;
  font-weight: 600;
  color: ${colors.basic900};
`;

export const body = css`
  ${defaultTextStyle};
  font-size: 15px;
  font-weight: 400;
  color: ${colors.basic900};
`;

export const body2 = css`
  ${defaultTextStyle};
  font-size: 13px;
  font-weight: 400;
  color: ${colors.basic900};
`;

export const caption = css`
  ${defaultTextStyle};
  font-size: 12px;
  font-weight: 400;
  color: ${colors.basic900};
  letter-spacing: 0.15px;
`;

export const caption2 = css`
  ${defaultTextStyle};
  font-size: 12px;
  font-weight: 600;
  color: ${colors.basic900};
`;

export const label = css`
  ${defaultTextStyle};
  font-size: 12px;
  font-weight: 700;
  color: ${colors.basic900};
  text-transform: uppercase;
`;
