import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import StarIcon from '@mui/icons-material/Star';
import StarEmptyIcon from '@mui/icons-material/StarBorder';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { RateWrapper } from './styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'grc-rate',
});

function Rate({
  max,
  value,
  textColor,
}) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <RateWrapper textColor={textColor}>
        {Object.keys([...new Array(max)]).map((i) => (
          <Fragment key={i}>
            {(Math.floor(value) > i) && (
              <StarIcon />
            )}
            {(Math.floor(value) <= i) && (
              <StarEmptyIcon />
            )}
          </Fragment>
        ))}
      </RateWrapper>
    </StylesProvider>
  );
}

Rate.defaultProps = {
  max: 5,
  value: 0,
  textColor: '',
};

Rate.propTypes = {
  max: PropTypes.number,
  value: PropTypes.number,
  textColor: PropTypes.string,
};

export default Rate;
