import styled, { css } from 'styled-components';

export const VideoWrapper = styled.div`
  display: flex;
  align-self: stretch;

  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${(props) => props.imageUrl && css`
    background-image: url("${props.imageUrl}");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  color: inherit;

  > svg {
    font-size: 5em;
  }

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
