import styled from 'styled-components';
import colors from '../../constants/colors';

export const EditorWrapper = styled.div`
  background-color: transparent;
  color: ${colors.grey600};
  min-height: 200px;

  .editor_content {
    background-color: transparent;
    border: 1px solid ${colors.grey200};
    color: inherit;
    min-height: 150px;
    padding: 0 1em;
  }
`;
