import React from 'react';
import PropTypes from 'prop-types';
import {
  DataWrapper,
  DataTitle,
  DataValue,
} from './styles';

function DataPair({
  title,
  value,
}) {
  return (
    <DataWrapper>
      <DataTitle>{title}</DataTitle>
      <DataValue>{value}</DataValue>
    </DataWrapper>
  );
}

DataPair.defaultProps = {
  title: '--',
  value: '--',
};

DataPair.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default DataPair;
