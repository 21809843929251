import React from 'react';
import PropTypes from 'prop-types';
import VideoBackground from '../VideoBackground/VideoBackground';
import {
  ImageCover,
  MediaWrapper,
} from './styles';

function MediaContent({
  imageUrl,
  videoUrl,
  mediaBgColor,
}) {
  return (
    <MediaWrapper mediaBgColor={mediaBgColor}>
      {imageUrl && <ImageCover imageUrl={imageUrl} />}
      {videoUrl
        && (
          <VideoBackground
            src={videoUrl}
            autoPlay={false}
            loop={false}
            muted={false}
            showControl
          />
        )}
    </MediaWrapper>
  );
}

MediaContent.defaultProps = {
  imageUrl: '',
  videoUrl: '',
  mediaBgColor: '',
};

MediaContent.propTypes = {
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  mediaBgColor: PropTypes.string,
};

export default MediaContent;
