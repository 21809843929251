import { styled as MuiStyled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import styled, { css } from 'styled-components';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { label, body } from '../constants/typography';

import colors from '../constants/colors';

const inputStyles = css`
  ${body};

  flex: 1 1 auto;

  border: none;
  outline: none;
  margin: 0 0.5em;
  padding: 0;
  width: 100%;

  &::placeholder {
    ${body};
  }
`;

export const StyledLabel = MuiStyled(InputLabel)({
  marginBottom: '0.5em',
});

export const StyledInput = MuiStyled(InputBase)({
  display: 'flex',
  flex: '1 1 auto',
  backgroundColor: 'inherit',
  color: 'inherit',
  minHeight: '0',
  minWidth: '100px',
  position: 'relative',
  zIndex: '1',
  border: `1px solid ${colors.grey200}`,

  fontFamily: "'Open Sans', 'helvetica', 'sans-serif'",
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0',
  textAlign: 'left',

  '&.noborder': {
    border: 'none',
  },

  '&.disabled': {
    backgroundColor: colors.basic200,
  },

  '&.Mui-disabled': {
    backgroundColor: colors.basic200,

    '& input': {
      backgroundColor: colors.basic200,
    },
  },

  '&.Mui-focused': {
    backgroundColor: 'inherit',
    borderColor: colors.primary600,
  },

  '&:focused': {
    backgroundColor: 'inherit',
    borderColor: colors.primary600,
  },

  '& .MuiSelect-root': {
    flex: '1 0 auto',
    boxSizing: 'border-box',
    padding: '0.5em 0',
  },

  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    height: '3rem !important',
    boxSizing: 'border-box',

    '&:focus': {
      backgroundColor: 'inherit',
    },

    '& option': {
      color: colors.grey800,
      padding: '0.25em',
    },
  },
  '& .MuiSelect-icon': {
    color: 'inherit',
  },
  '&.Mui-error': {
    borderColor: colors.red600,
  },
});

export const EndAdornmentIcon = styled.div`
  display: flex;
  flex: 0 0 50px;
`;

export const StyledInlineInput = MuiStyled(StyledInput)({
  border: `1px solid ${colors.grey200}`,
  boxSizing: 'border-box',
  flex: '1 1 auto',
  minWidth: '110px',
  '&.Mui-error': {
    borderColor: colors.red600,
  },
  '& .MuiSelect-select': {
    fontWeight: '400',
    '& option': {
      color: colors.grey800,
      padding: '0.25em',
    },
  },
});

export const InputIcon = MuiStyled(InputAdornment)({
  color: colors.grey600,
  flex: '0 0 auto',
  minWidth: '1.5rem',
  paddingLeft: '1em',
});

export const StyledButton = MuiStyled(Button)((props) => ({
  backgroundColor: props.bgColor || colors.groupizeBrand,
  borderColor: props.bgColor || colors.groupizeBrand,
  color: props.textColor || colors.grey50,
  '&:hover': {
    backgroundColor: props.bgColor || colors.groupizeBrand,
    borderColor: props.bgColor || colors.groupizeBrand,
  },
}));

export const FieldInputContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: ${(props) => (props.flexGrow ? 1 : 0)};
  justify-content: flex-start;

  background-color: ${(props) => props.bgColor || 'transparent'};
  border: 1px solid ${(props) => props.borderColor || 'transparent'};
  border-radius: ${(props) => props.borderRadius || '0'};
  box-sizing: border-box;

  margin: 0.5em;
  min-width: 70px;
  min-height: ${(props) => (props.minheight || '0')};
  padding: ${(props) => (props.padding || '0')};
  position: relative;

  ${(props) => props.isDisabled && css`
    opacity: 0.7;
  `}

  ${(props) => props.small && css`
    font-size: 0.8rem;
  `}

  .MuiInputBase-root {
    min-height: ${(props) => (props.minheight || '20px')} !important;
    background-color: ${(props) => ((props.bgColor !== 'transparent') ? props.bgColor : 'white')};
    color: ${(props) => props.textColor || 'inherit'};
    padding: 0 0.5em;

    ${(props) => props.noborder && css`
      border: none;
    `}

    &.Mui-focused {
      background-color: ${(props) => ((props.bgColor !== 'transparent') ? props.bgColor : 'white')};
    }
  }

  [role="radiogroup"] label {
    text-transform: capitalize;
  }

  label {
    ${label};

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    flex: 0 0 auto;

    color: ${(props) => props.labelColor || 'inherit'};
    margin-bottom: 0.5em;
    margin-right: 0.5em;

    small {
      margin-left: 0.5em;
    }
  }

  .MuiSelect-selectMenu {
    font-size: 0.9em;
  }

  .Mui-error input {
    border-color: ${colors.danger500};
  }

  input:not(.MuiSelect-nativeInput) {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    background-color: ${colors.basic100};
    color: ${(props) => props.textColor || colors.textColorHint};
    caret-color: ${(props) => props.textColor || colors.textColorHint};
    padding: 0 0.5em;
    height: 3rem;

    position: relative;
    transition: all 0.3s ease;

    &.currency {
      height: 100%;
    }

    &.passcode {
      border: none;

      &:hover {
        box-shadow: none;
        border: 0;
      }
    }

    &:focus {
      caret-color: ${colors.brand600};
    }

    &:focus-within {
      input {
        caret-color: ${colors.brand600};
      }
    }

    .Mui-success &,
    &.success {
      border-color: ${colors.success500};
    }

    .Mui-error &,
    &.error {
      border-color: ${colors.danger500};
    }

    &.info {
      border-color: ${colors.info500};
    }

    &.warning {
      border-color: ${colors.warning500};
    }

    &.disabled {
      background-color: ${colors.basic200};

      input {
        ${body};
      }
    }

    &.no-shadow {
      box-shadow: none;
      border: 1px solid ${colors.borderColor};
    }

    textarea,
    input {
      ${inputStyles};

      ${(props) => props.noborder && css`
        border: none;
      `}
    }

    .icon-btn {
      display: flex;
      align-items: center;
      align-self: stretch;
      cursor: pointer;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  textarea {
    color: ${(props) => props.textColor || colors.grey800};
    min-height: ${(props) => (props.minheight || '150px')};
    font-weight: 400;
    padding: 0.5em;

    &::placeholder {
      color: ${(props) => props.textColor || colors.grey800};
      opacity: 0.85;
    }
  }

  ${(props) => (props.variant === 'simple') && css`
    background-color: inherit;
    border: none;
    padding: ${props.padding || '0.5em 0'};

    .MuiInputBase-root {
      min-height: ${props.minheight || '0'};
    }
  `};

  ${(props) => (props.variant === 'inline') && css`
    flex-flow: row nowrap;
    align-items: center;
    border: none;
    padding: ${props.padding || '0.5em 0'};

    label {
      margin: 0 1em 0 0;
      min-width: 60px;
    }

    > .MuiFormGroup-root {
      flex-direction: row;

      label {
        min-width: 0;
      }
    }
  `}

  ${(props) => props.small && css`
    margin: 0.25em;

    input {
      font-size: 0.9em;
    }
  `}

  ${(props) => props.noWrapper && css`
    background-color: transparent;
    border: none;
    padding: 0;
  `}

  ${(props) => (props.noMargin || props.nomargin) && css`
    margin: 0;
  `}
`;

export const Required = styled.span`
  color: ${colors.red600};
  margin-right: 0.25em;

  &:before {
    content: '*';
  }
`;

export const AutoCompleteContainer = styled.div`
  border: 1px solid ${colors.grey200};
  border-radius: 6px;

  position: absolute;
  left: 0;
  top: 100%;
  z-index: 999;
`;

export const AutoCompleteItem = styled.div`
  background-color: ${colors.white};
  color: ${colors.textColorHint};
  cursor: pointer;

  padding: 0.5em 1em;
  transition: background-color 0.3s ease;

  &.active {
    background-color: ${colors.grey50};
  }
`;

export const DateFieldInput = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;

  border: 1px solid ${colors.grey200};
  transition: border-color 0.3s ease;

  &:focus-within {
    border-color: ${colors.groupizeBrand};
  }

  &.disabled {
    background-color: ${colors.basic200};
    opacity: 1 !important;
  }

  ${(props) => props.variant === 'inline' && css`
    border: 1px solid ${colors.grey200};
    flex: 1 1 auto;
    padding: 0;
  `}

  .react-datepicker-wrapper {
    display: flex;
    flex: 1 1 auto;
  }

  .react-datepicker-popper {
    z-index: 9999;
  }

  .react-datepicker__header__dropdown {
    padding: 0.5em;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    top: 5px;
  }

  .DateInput_fang {
    transform: translateY(calc(-100% + 2px));
    z-index: 999 !important;
  }

  .SingleDatePickerInput {
    background-color: inherit;
  }

  .SingleDatePicker_picker {
    top: calc(100% + 15px) !important;
    z-index: 998 !important;
  }

  .SingleDatePicker_picker__portal {
    z-index: 998 !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: ${(props) => props.bgColor || colors.groupizeBrand};
    border-color: ${(props) => props.bgColor || colors.groupizeBrand};
  }

  .DayPickerKeyboardShortcuts_show__bottomRight,
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right-color: ${(props) => props.bgColor || colors.groupizeBrand};
  }

  .DateInput__block {
    display: flex;
    font-size: 1rem;
  }

  .DateInput__disabled {
    background-color: ${colors.basic200};
  }

  .DateInput_input {
    ${inputStyles};
    box-sizing: border-box;
    &__disabled {
      background-color: ${colors.basic200};
      color:  ${colors.grey400};
    }
  }
`;

export const ColorButton = styled(Button)`
  background-color: ${(props) => props.bgcolor || colors.groupizeBrand} !important;
  border-color: ${(props) => props.bgcolor || colors.groupizeBrand} !important;
  color: ${(props) => props.textColor || props.textcolor || colors.white} !important;

  &.MuiButton-outlined {
    background-color: transparent !important;
    color: ${(props) => props.bgcolor || colors.groupizeBrand} !important;
  }
`;

export const RadioIcon = MuiStyled('span')({
  borderRadius: '50%',
  flex: '0 0 20px',
  height: 20,
  width: 20,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.08)',
  backgroundColor: '#FFFFFF',
  boxSizing: 'content-box',
  'input:hover ~ &': {
    backgroundColor: '#FFFFFF',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
});

export const RadioCheckedIcon = MuiStyled(RadioIcon)({
  '&:before': {
    content: '""',
    display: 'block',
    width: 10,
    height: 10,
    backgroundColor: '#475242',
    borderRadius: '50%',
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

export const GroupizeBtn = MuiStyled(Button)({
  color: colors.groupizeBrand,
  borderColor: colors.groupizeBrand,
  '&:hover': {
    backgroundColor: colors.grey50,
    boxShadow: 'none',
  },
  '&.MuiButton-contained': {
    backgroundColor: colors.groupizeBrand,
    boxShadow: 'none',
    color: colors.white,
    '&:hover': {
      backgroundColor: '#8bce6f',
      boxShadow: 'none',
    },
  },
  '&.Mui-disabled': {
    backgroundColor: colors.grey400,
  },
  '&.MuiButton-outlined.Mui-disabled': {
    backgroundColor: colors.white,
  },
});
