/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Tooltip } from '@mui/material';
import { FiAlertTriangle } from 'react-icons/fi';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment-timezone';
import {
  DateFieldInput,
  FieldWrapper,
  InputIcon,
  Required,
  StyledInput,
} from '../../helpers/styles';
import { FlexFlow } from '../../helpers/DisplayHelpers';
import colors from '../../constants/colors';

function TimeField({
  id,
  label,
  small,
  value,
  variant,
  nomargin,
  minheight,
  isDisabled,
  error,
  flexGrow,
  startAdornment,
  bgColor,
  textColor,
  borderColor,
  borderRadius,
  helperText,
  isRequired,
  accentColor,
  defaultValue,
  onChanged,
}) {
  const [inputValue, setValue] = useState(moment(defaultValue, 'HH:mm'));
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const newValue = moment(value, 'HH:mm');
    if (newValue.isValid()) {
      setValue(newValue);
    }
  }, [value]);

  const startContent = startAdornment ? (
    <InputIcon position="start">
      {startAdornment}
    </InputIcon>
  ) : null;

  const handleChange = (e) => {
    setValue(e);
    onChanged(moment(e).format('HH:mm'));
  };

  const isInline = ['simple', 'inline'].includes(variant);
  return (
    <FieldWrapper
      small={small}
      variant={variant}
      nomargin={nomargin}
      flexGrow={flexGrow}
      minheight={minheight}
      bgColor={bgColor}
      textColor={textColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
    >
      <FlexFlow
        nowrap
        shrink
        grow
        splitSpace="0.5rem"
        align="center"
      >
        <FlexFlow
          column={!isInline}
          nowrap
          grow
          shrink
          align={isInline ? 'center' : 'stretch'}
        >
          {label && label.length > 0 && (
            <InputLabel>
              {isRequired && <Required />}
              {label}
            </InputLabel>
          )}
          <DateFieldInput
            variant={variant}
            error={error}
            bgColor={accentColor}
            color={textColor}
            className={isDisabled ? 'disabled' : ''}
          >
            {startContent}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                open={isOpen}
                disabled={isDisabled}
                id={id}
                name={id}
                value={moment(inputValue)}
                onChange={handleChange}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                renderInput={({ inputRef, inputProps }) => {
                  const thisInputProps = { ...inputProps, placeholder: 'Enter time', readOnly: true };
                  return (
                    <StyledInput
                      onClick={() => setOpen(true)}
                      className="noborder"
                      ref={inputRef}
                      {...thisInputProps}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </DateFieldInput>

        </FlexFlow>

        {helperText && helperText.length > 0 && (
          <Tooltip title={helperText}>
            <div>
              <FiAlertTriangle />
            </div>
          </Tooltip>
        )}
      </FlexFlow>
    </FieldWrapper>
  );
}

TimeField.defaultProps = {
  label: '',
  value: '',
  variant: '',
  minheight: '0',
  nomargin: false,
  isRequired: false,
  flexGrow: true,
  small: false,
  startAdornment: null,
  bgColor: 'transparent',
  accentColor: colors.groupizeBrand,
  textColor: 'inherit',
  borderColor: 'transparent',
  borderRadius: '0',
  helperText: '',
  isDisabled: false,
  error: false,
  defaultValue: '07:00',
};

TimeField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  small: PropTypes.bool,
  variant: PropTypes.string,
  minheight: PropTypes.string,
  nomargin: PropTypes.bool,
  flexGrow: PropTypes.bool,
  isRequired: PropTypes.bool,
  startAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  accentColor: PropTypes.string,
  borderRadius: PropTypes.string,
  helperText: PropTypes.string,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChanged: PropTypes.func.isRequired,
};

export default TimeField;
