/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import Avatar from '../Avatar';
import { FlexFlow } from '../helpers/DisplayHelpers';
import ChangeLabelForm from './ChangeLabelForm';
import {
  MessageWrapper,
  MessageAuthor,
  MessageDate,
  MessageContent,
  MessageContentText,
  MessageContentTitle,
  LabelsWrapper,
  LabelItem,
} from './styles';

function RawHTML({ children, className }) {
  const isHtml = children.match(/<html/) || children.match(/<div/);
  if (isHtml) {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }}
      />
    );
  }

  const style = { whiteSpace: 'pre-line' };
  const content = children.replace(/\n/g, '<br />');

  return (
    <div
      style={style}
      className={className}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}

RawHTML.defaultProps = {
  className: '',
};

RawHTML.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function MessageItem({
  message: {
    title,
    message,
    author = {},
    attachments = [],
    date,
    label = '',
  },
  isNote,
}) {
  const allLabels = ['property', 'internal', 'other'];
  const [messageLabel, setMessageLabel] = useState(label);
  const [selectLabelData, setSelectLabelData] = useState({
    openModal: false,
    onChangeLabel: (v) => {
      setMessageLabel(v);
      setSelectLabelData({ ...selectLabelData, openModal: false });
    },
    onClose: () => setSelectLabelData({ ...selectLabelData, openModal: false }),
    value: '',
    options: allLabels,
  });

  useEffect(() => {
    const hasLabel = allLabels.includes(label);
    setMessageLabel(hasLabel ? label : 'Add Label');
    return () => {
      setMessageLabel('');
    };
  }, []);

  const showLabelModal = () => {
    setSelectLabelData({
      ...selectLabelData,
      openModal: true,
      value: messageLabel,
    });
  };

  return (
    <MessageWrapper>
      <Avatar avatarUrl={author.avatarUrl} />
      <FlexFlow shrink grow column margin="0 0 0 1em">
        <MessageAuthor>
          {author.name}

          <MessageDate>
            {date}
          </MessageDate>
        </MessageAuthor>
        <MessageContent>
          {!isNote && (label && label.length > 0) && (
            <LabelsWrapper>
              <LabelItem label={messageLabel} onClick={showLabelModal}>
                {messageLabel}
              </LabelItem>
            </LabelsWrapper>
          )}

          <MessageContentTitle>
            {title}
          </MessageContentTitle>

          <MessageContentText>
            <RawHTML>
              {message}
            </RawHTML>
          </MessageContentText>

          {!isNote && (attachments && attachments.length > 0) && (
            <>
              <MessageContentTitle>
                {attachments.length}
                {' '}
                {pluralize('attachment', attachments.length)}
              </MessageContentTitle>

              {attachments.map((a) => (
                <a key={a.link} href={a.link} target="blank">
                  {a.name}
                </a>
              ))}
            </>
          )}
        </MessageContent>
      </FlexFlow>
      <ChangeLabelForm {...selectLabelData} />
    </MessageWrapper>
  );
}

MessageItem.defaultProps = {
  message: {},
  isNote: false,
};

MessageItem.propTypes = {
  message: PropTypes.shape(),
  isNote: PropTypes.bool,
};

export default MessageItem;
