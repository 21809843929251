/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';
import {
  Box,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  Tooltip,
} from '@mui/material';
import { FlexFlow } from '../../helpers/DisplayHelpers';
import colors from '../../constants/colors';
import {
  FieldWrapper,
  StyledInput,
  StyledInlineInput,
  InputIcon,
  Required,
} from '../../helpers/styles';

function SelectField({
  id,
  name,
  label,
  items,
  value,
  defaultValue,
  variant,
  multiple,
  nomargin,
  minheight,
  onChange,
  flexGrow,
  isNative,
  startAdornment,
  bgColor,
  textColor,
  labelColor,
  borderColor,
  borderRadius,
  small,
  helperText,
  isRequired,
  displayEmpty,
  padding,
  noborder,
  ...rest
}) {
  const getCorrectValue = (v) => {
    if (multiple && typeof value === 'string') {
      return v.split(',');
    }
    return v;
  };

  const [currentValue, setValue] = useState(getCorrectValue(value));

  useEffect(() => {
    setValue(getCorrectValue(value));
  }, [value]);

  const handleSelect = (e) => {
    const {
      target: { value: v },
    } = e;
    if (multiple) {
      const val = typeof v === 'string' ? v.split(',') : v;
      setValue(val);
      onChange(val);
    } else if (v !== currentValue) {
      setValue(v);
      onChange(v);
    }
  };

  const startContent = startAdornment ? (
    <InputIcon position="start">
      {startAdornment}
    </InputIcon>
  ) : null;

  let customInput;
  switch (variant) {
    case 'simple':
    case 'inline':
      customInput = (
        <StyledInlineInput
          id={id}
          name={name}
          value={currentValue}
          startAdornment={startContent}
          {...rest}
        />
      );
      break;
    default:
      customInput = (
        <StyledInput
          id={id}
          name={name}
          value={currentValue}
          startAdornment={startContent}
          {...rest}
        />
      );
      break;
  }

  const isInline = ['simple', 'inline'].includes(variant);

  const labelByValue = (v) => {
    const strVal = String(v);
    const resultItem = items.find((i) => i.value === strVal);
    return resultItem ? resultItem.label : '';
  };

  return (
    <FieldWrapper
      variant={variant}
      nomargin={nomargin}
      flexGrow={flexGrow}
      minheight={minheight}
      bgColor={bgColor}
      textColor={textColor}
      labelColor={labelColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      small={small}
      padding={padding}
      noborder={noborder}
    >
      <FlexFlow
        nowrap
        shrink
        grow
        splitSpace="0.5rem"
        align="center"
      >
        <FlexFlow
          column={!isInline}
          nowrap
          grow
          shrink
          align={isInline ? 'center' : 'stretch'}
        >
          {label && label.length > 0 && (
            <InputLabel>
              {isRequired && <Required />}
              {label}
            </InputLabel>
          )}

          <Select
            displayEmpty
            native={isNative}
            value={currentValue}
            multiple={multiple}
            onChange={handleSelect}
            input={customInput}
            renderValue={(selected) => {
              if (!multiple) return labelByValue(selected);
              return (
                <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                }}
                >
                  {selected.map((v) => (
                    <Chip key={v} label={labelByValue(v)} />
                  ))}
                </Box>
              );
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {isNative && defaultValue && defaultValue.length > 0 && (
              <option value="" disabled>
                {defaultValue}
              </option>
            )}

            {isNative && items.map((item) => (
              <option key={item.value} value={item.value} disabled={item.disabled}>
                {item.label}
              </option>
            ))}

            {!isNative && defaultValue && defaultValue.length > 0 && (
              <MenuItem value="" disabled>
                <em>{defaultValue}</em>
              </MenuItem>
            )}

            {!isNative && items.map((item) => {
              if (item.hidden) return null;
              return (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  disabled={item.disabled}
                  selected={currentValue === item.value}
                >
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FlexFlow>

        {helperText && helperText.length > 0 && (
          <Tooltip title={helperText}>
            <div>
              <FiAlertTriangle />
            </div>
          </Tooltip>
        )}
      </FlexFlow>
    </FieldWrapper>
  );
}

SelectField.defaultProps = {
  label: '',
  value: '',
  nomargin: true,
  small: false,
  isNative: false,
  isRequired: false,
  displayEmpty: false,
  flexGrow: 0,
  defaultValue: 'Select',
  variant: '',
  minheight: '20px',
  startAdornment: null,
  bgColor: 'transparent',
  textColor: colors.textColorHint,
  labelColor: 'inherit',
  borderColor: 'transparent',
  borderRadius: '0',
  helperText: '',
  padding: '0',
  noborder: false,
  multiple: false,
};

SelectField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.string,
    ),
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  variant: PropTypes.string,
  nomargin: PropTypes.bool,
  small: PropTypes.bool,
  isNative: PropTypes.bool,
  flexGrow: PropTypes.number,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  labelColor: PropTypes.string,
  borderColor: PropTypes.string,
  minheight: PropTypes.string,
  isRequired: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      value: PropTypes.string,
    }),
  ).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  startAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  borderRadius: PropTypes.string,
  helperText: PropTypes.string,
  padding: PropTypes.string,
  noborder: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default SelectField;
