/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { PickerOverlay } from 'filestack-react';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import {
  FiAlertTriangle,
  FiPaperclip,
  FiTrash2,
  FiDownloadCloud,
  FiEye,
} from 'react-icons/fi';
import Iframe from 'react-iframe';
import { red } from '@mui/material/colors';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { FlexFlow } from '../../helpers/DisplayHelpers';
import Button from '../Button';
import { Required } from '../../helpers/styles';
import {
  ActionBtns,
  FilePickerWrapper,
  FilePickerContent,
  RemoveBtn,
  FileList,
  FileItem,
  FileName,
  FileActions,
  HelperText,
} from './styles';
import colors from '../../constants/colors';

function FilePicker({
  apiKey,
  policy,
  signature,
  btnAddName,
  btnRemoveName,
  hasWrapper,
  onSubmit,
  maxFiles,
  fileType,
  variant,
  isRequired,
  isDisabled,
  cname,
  cropOptions,
  fromSources,
  helperText,
  errorsText,
  bgcolor,
  textcolor,
  showActionButtons,
  inputData: {
    id,
    label,
    value,
  },
}) {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(value || []);
  const [previewUrl, setPreviewUrl] = useState('');

  const [previewModalData, setPreviewModalData] = useState({
    open: false,
    title: 'File Preview',
    maxWidth: 'md',
    showActionBtns: true,
    cancelBtnCopy: 'Close',
    confirmBtnCopy: false,
    handleCancel: () => setPreviewModalData({ ...previewModalData, open: false }),
  });
  const [deleteModalData, setDeleteModalData] = useState({
    open: false,
    title: 'Delete Uploaded File',
    maxWidth: 'sm',
    showActionBtns: true,
    cancelBtnCopy: 'Close',
    confirmBtnCopy: 'Delete',
    handleCancel: () => setDeleteModalData({ ...deleteModalData, open: false }),
    handleConfirm: () => setDeleteModalData({ ...deleteModalData, open: false }),
  });

  const showPreviewModal = (url) => {
    setPreviewUrl(url);
    setPreviewModalData({
      ...previewModalData,
      open: true,
    });
  };

  const showDeleteModal = ({ onDelete }) => {
    setDeleteModalData({
      ...deleteModalData,
      handleConfirm: onDelete,
      open: true,
    });
  };

  const showPicker = () => {
    setIsPickerVisible(true);
  };

  const hidePicker = () => {
    setIsPickerVisible(false);
  };

  useEffect(() => {
    setUploadedFiles(value || []);
  }, [value]);

  const useComponentDidMount = (func) => useEffect(func, []);
  const useComponentWillMount = (func) => {
    const willMount = useRef(true);
    if (willMount.current) {
      func();
    }
    useComponentDidMount(() => {
      willMount.current = false;
    });
  };

  useComponentWillMount(() => { });

  const pickerOptions = {
    maxFiles,
    transformations: {
      circle: false,
      rotate: false,
      crop: cropOptions,
    },
    fromSources,
    onClose: hidePicker,
  };

  if (fileType && fileType.length > 0) {
    if (fileType === 'image/*') {
      pickerOptions.accept = [
        '.png',
        '.jpg',
        '.jpeg',
        '.bmp',
        '.gif',
        '.tiff',
        '.tif',
      ];
    } else {
      pickerOptions.accept = fileType;
    }
  } else {
    pickerOptions.accept = [
      '.pdf',
      '.doc',
      '.docx',
      '.ppt',
      '.pptx',
      '.xls',
      '.xlsx',
      '.csv',
      '.png',
      '.jpg',
      '.jpeg',
      '.bmp',
      '.gif',
      '.webp',
      '.tiff',
      '.tif',
      'video/*',
      'audio/*',
      '.txt',
      '.rtf',
      '.zip',
    ];
  }

  const clientOptions = {
    cname,
    security: {
      policy,
      signature,
    },
  };

  const handleReset = () => {
    const newArray = uploadedFiles
      .filter((f) => (f.id))
      .map((f) => (
        { ...f, isDeleted: true }
      ));

    setUploadedFiles(newArray);
    onSubmit(newArray);
  };

  const handleRemoveFile = (file) => {
    const newArray = uploadedFiles
      .filter((f) => (f.id || (!f.id && f.filename !== file.filename)))
      .map((f) => (
        (f.filename === file.filename) ? { ...f, isDeleted: true } : { ...f }
      ));

    setUploadedFiles(newArray);
    onSubmit(newArray);
  };

  const handleSubmit = (result) => {
    const { filesUploaded, filesFailed } = result;
    const existingFiles = uploadedFiles || [];

    if (filesUploaded.length > 0) {
      const existingFileNames = existingFiles.map(({ filename }) => filename);
      const newFiles = filesUploaded.filter((f) => !existingFileNames.includes(f.filename));
      const resultFiles = existingFiles.concat(newFiles);

      setUploadedFiles(resultFiles);
      onSubmit(resultFiles);
    }
    if (filesFailed.length > 0) {
      console.error('Failed to upload:', filesFailed);
    }

    hidePicker();
  };

  const handleError = (error) => {
    console.log(error);
  };

  return (
    <>
      <FilePickerWrapper hasWrapper={hasWrapper}>
        {label && (label.length > 0) && (
          <InputLabel>
            {isRequired && <Required />}
            {label}
          </InputLabel>
        )}

        <FilePickerContent variant={variant}>
          {uploadedFiles && uploadedFiles.length > 0 && (
            <FileList>
              {uploadedFiles
                .filter((f) => !f.isDeleted)
                .map((f) => (
                  <FileItem key={f.filename}>
                    <FiPaperclip />
                    <FileName>
                      {f.filename}
                    </FileName>

                    {showActionButtons && (
                      <FileActions>
                        <IconButton
                          href={`${f.url}?signature=${signature}&policy=${policy}`}
                          target="_blank"
                        >
                          <FiDownloadCloud />
                        </IconButton>

                        <IconButton
                          onClick={() => showPreviewModal(`https://www.filepicker.io/api/preview/${f.handle}?signature=${signature}&policy=${policy}`)}
                          target="_blank"
                        >
                          <FiEye />
                        </IconButton>

                        {!isDisabled && (
                          <IconButton onClick={() => showDeleteModal({
                            onDelete: () => {
                              handleRemoveFile(f);
                              setDeleteModalData({ ...deleteModalData, open: false });
                            },
                          })}
                          >
                            <FiTrash2 />
                          </IconButton>
                        )}
                      </FileActions>
                    )}
                  </FileItem>
                ))}
            </FileList>
          )}

          {!isDisabled && (
            <ActionBtns variant={variant}>
              <Button
                variant="contained"
                textcolor={textcolor}
                bgcolor={bgcolor}
                onClick={showPicker}
              >
                {btnAddName}
              </Button>
              {uploadedFiles
                && (uploadedFiles.filter((f) => !f.isDeleted).length > 0)
                && (
                  <RemoveBtn onClick={() => handleReset()}>
                    {btnRemoveName}
                  </RemoveBtn>
                )}
            </ActionBtns>
          )}
        </FilePickerContent>

        <HelperText>
          {helperText}
        </HelperText>

        {errorsText && errorsText.length > 0 && (
          <HelperText color={red[600]}>
            <FlexFlow splitSpace="0.5em" align="center">
              <FiAlertTriangle />
              <span>
                {errorsText}
              </span>
            </FlexFlow>
          </HelperText>
        )}
      </FilePickerWrapper>

      {isPickerVisible && (
        <PickerOverlay
          id={id}
          apikey={apiKey}
          clientOptions={clientOptions}
          pickerOptions={pickerOptions}
          onUploadDone={handleSubmit}
          onError={handleError}
        />
      )}

      <ConfirmationDialog {...previewModalData}>
        <Iframe
          url={previewUrl}
          width="auto"
          height="450px"
          id="previewFile"
          display="initial"
          position="relative"
        />
      </ConfirmationDialog>

      <ConfirmationDialog {...deleteModalData}>
        Are you sure?
      </ConfirmationDialog>
    </>
  );
}

FilePicker.defaultProps = {
  cropOptions: {
    aspectRatio: 1,
    force: true,
  },
  fileType: '',
  hasWrapper: false,
  isDisabled: false,
  isRequired: false,
  btnAddName: 'Upload',
  btnRemoveName: 'Remove',
  helperText: '',
  errorsText: '',
  maxFiles: 1,
  variant: '',
  cname: '',
  fromSources: ['local_file_system', 'imagesearch'],
  showActionButtons: true,
  inputData: {
    id: '',
    label: '',
    value: [],
  },
  bgcolor: colors.groupizeBrand,
  textcolor: colors.white,
};

FilePicker.propTypes = {
  apiKey: PropTypes.string.isRequired,
  policy: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cropOptions: PropTypes.shape(),
  maxFiles: PropTypes.number,
  fileType: PropTypes.string,
  btnAddName: PropTypes.string,
  cname: PropTypes.string,
  btnRemoveName: PropTypes.string,
  hasWrapper: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  variant: PropTypes.string,
  fromSources: PropTypes.shape(),
  inputData: PropTypes.shape(),
  helperText: PropTypes.string,
  errorsText: PropTypes.string,
  showActionButtons: PropTypes.bool,
  bgcolor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  textcolor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default FilePicker;
