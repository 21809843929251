import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VideoCover from 'react-video-cover';
import { VideoWrapper } from './styles';

function VideoBackground({
  fileUrl,
  showControl,
  autoPlay,
  src,
  muted,
  loop,
}) {
  const [isPaused, setPaused] = useState(!autoPlay);
  const [ref, setRef] = useState();

  const videoOptions = {
    src,
    loop,
    muted,
    autoPlay,
    controls: showControl,
    ref: (videoRef) => setRef(videoRef),
  };

  const onTogglePlay = () => {
    if (ref) {
      if (ref.paused) {
        ref.play();
      } else {
        ref.pause();
      }
      setPaused(ref.paused);
    }
  };

  return (
    <VideoWrapper fileUrl={fileUrl}>
      <VideoCover
        videoOptions={{
          onClick: onTogglePlay,
          ...videoOptions,
        }}
      />
    </VideoWrapper>
  );
}

VideoBackground.defaultProps = {
  fileUrl: '',
  autoPlay: true,
  muted: true,
  loop: true,
  showControl: false,
};

VideoBackground.propTypes = {
  src: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  showControl: PropTypes.bool,
  fileUrl: PropTypes.string,
};

export default VideoBackground;
