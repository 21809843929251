import React from 'react';
import PropTypes from 'prop-types';
import getSymbolFromCurrency from 'currency-symbol-map';
import numeral from 'numeral';

function PriceText({ sum, currency }) {
  const symbol = getSymbolFromCurrency(currency);
  return (
    <span>
      {`${symbol || ''}${numeral(sum / 100).format('0,0.00')}`}
    </span>
  );
}

PriceText.defaultProps = {
  sum: 0,
  currency: '$',
};

PriceText.propTypes = {
  sum: PropTypes.number,
  currency: PropTypes.string,
};

export default PriceText;
