import React from 'react';
import PropTypes from 'prop-types';
import { PanelWrapper } from './styles';

function Panel({ className, children }) {
  return (
    <PanelWrapper className={className}>
      {children}
    </PanelWrapper>
  );
}

Panel.defaultProps = {
  className: '',
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

export default Panel;
