import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import InputLabel from '@mui/material/InputLabel';

import { EditorWrapper } from './styles';
import { toolBarOptions } from './toolBarOptions';

function WYSIWYGEditor({
  id,
  label,
  value,
  options,
  placeholder,
  onChange,
}) {
  const convertToEditorValue = (newValue) => {
    const blocksFromHtml = htmlToDraft(newValue);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState) || '';
  };

  const [editorState, setEditorState] = useState(convertToEditorValue(value));

  useEffect(() => {
    const editorValue = convertToEditorValue(value);
    setEditorState(editorValue);

    return () => {
      setEditorState('');
    };
  }, [value]);

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);

    const editorSourceHTML = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    if (onChange instanceof Function) {
      onChange(editorSourceHTML);
    }
  };

  const editorOptions = toolBarOptions;
  editorOptions.options = options;

  return (
    <EditorWrapper>
      {label && label.length > 0 && (
        <InputLabel>{label}</InputLabel>
      )}
      <Editor
        id={id}
        toolbar={editorOptions}
        defaultEditorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        editorClassName="editor_content"
        placeholder={placeholder}
      />
    </EditorWrapper>
  );
}

WYSIWYGEditor.defaultProps = {
  options: ['colorPicker', 'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
  placeholder: '',
  label: '',
};

WYSIWYGEditor.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default WYSIWYGEditor;
