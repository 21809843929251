import styled, { css } from 'styled-components';
import colors from '../constants/colors';

export const StartLine = styled.div`
  display: flex;
  flex: 1 1 auto;
  background-color: ${colors.grey200};
`;

export const EndLine = styled(StartLine)``;

export const Text = styled.p`
  display: flex;
  flex: 0 0 auto;
  margin: 0;

  color: inherit;
`;

export const DivideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex: 0 1 auto;

  ${(props) => props.type === 'horizontal' && css`
    flex-flow: row nowrap;
    padding: 1em 0;

    ${StartLine} {
      margin: ${props.hasLabel ? '0 0.5em 0 0' : '0'};
    }

    ${EndLine} {
      margin: ${props.hasLabel ? '0 0 0 0.5em' : '0'};
    }

    ${StartLine}, ${EndLine} {
      height: 1px;
      width: 100%;
    }
  `}

  ${(props) => props.type === 'vertical' && css`
    flex-flow: column nowrap;
    padding: 0 1em;

    ${StartLine}, ${EndLine} {
      height: 100%;
      width: 2px;
      margin: 0.5em 0;
    }
  `}
`;
