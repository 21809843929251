import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import {
  SideActions,
  SidePanelHeader,
  SideMainPanelContent,
  SideMainPanel, HeaderSubTitle,
} from './styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'grc-settings-panel',
});

function DashboardSettingsPanel({
  isOpened,
  handleOpen,
  mainForm,
  title,
  subTitle,
  isSeparated,
}) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <SideMainPanel
        variant="persistent"
        anchor="right"
        self={isSeparated}
        open={isOpened}
        panelWidth="500px"
      >
        <SidePanelHeader>
          <SideActions>
            {title}

            {subTitle && subTitle.length > 0 && (
              <HeaderSubTitle>
                {subTitle}
              </HeaderSubTitle>
            )}

            <IconButton onClick={handleOpen} className="icon-btn">
              <ForwardIcon />
            </IconButton>
          </SideActions>
        </SidePanelHeader>

        <SideMainPanelContent>
          {mainForm}
        </SideMainPanelContent>
      </SideMainPanel>
    </StylesProvider>
  );
}

DashboardSettingsPanel.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isOpened: PropTypes.bool,
  isSeparated: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  mainForm: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

DashboardSettingsPanel.defaultProps = {
  title: '',
  subTitle: '',
  isOpened: false,
  isSeparated: false,
};

export default DashboardSettingsPanel;
