import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled as MuiStyled } from '@mui/material/styles';

export const ToggleButtonGroup = MuiStyled((props) => (<MuiToggleButtonGroup {...props} />))({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'stretch',
  boxSizing: 'border-box',
  position: 'relative',
  '& > button': {
    display: 'flex',
    flex: '1 0 auto',
    height: '3em',
  },
});

function ButtonGroup({
  defaultValue,
  onChange,
  children,
}) {
  const [alignment, setAlignment] = useState(defaultValue);

  const handleChange = (event, value) => {
    setAlignment(value);
    if (onChange && typeof (onChange) === 'function') {
      onChange(alignment);
    }
  };

  return (
    <ToggleButtonGroup
      exclusive
      value={alignment}
      onChange={handleChange}
    >
      {children}
    </ToggleButtonGroup>
  );
}

ButtonGroup.defaultProps = {
  defaultValue: 'left',
};

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classes: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

export default ButtonGroup;
