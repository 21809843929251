/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '../TextField';
import { DEFAULT_CONVERTER, converters } from './transformers';
import PickerDialog from './PickerDialog';
import {
  ColorPickerArea,
  SampleBox,
} from './styles';

function ColorPicker({
  value: initValue,
  onChange,
  convert,
  // Text field
  id,
  name,
  label,
  hintText,
  placeholder,
  floatingLabelText,
  TextFieldProps,
}) {
  const [value, setLocalValue] = useState(initValue);
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setLocalValue(initValue);
  }, [initValue]);

  const handleInputChange = (e) => {
    const { value: newValue } = e.target;
    const isHexFull = /^#[0-9A-F]{6}$/i.test(newValue);
    const isHexShort = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(newValue);
    if (isHexFull || isHexShort) {
      onChange(newValue);
      setLocalValue(newValue);
    }
  };

  const handleChange = (c) => {
    const newValue = converters[convert](c);
    onChange(newValue);
    setLocalValue(newValue);
  };

  return (
    <ColorPickerArea>
      <TextField
        id={id}
        name={name}
        value={value}
        label={floatingLabelText || label}
        placeholder={hintText || placeholder}
        onClick={() => setShowPicker(true)}
        onChange={handleInputChange}
        endAdornment={(
          <InputAdornment position="end">
            <SampleBox color={value} />
          </InputAdornment>
        )}
        {...TextFieldProps}
      />
      {showPicker && (
        <PickerDialog
          value={value}
          onClickAway={() => {
            setShowPicker(false);
            onChange(value);
          }}
          onChange={handleChange}
        />
      )}
    </ColorPickerArea>
  );
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  convert: PropTypes.oneOf(Object.keys(converters)),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  hintText: PropTypes.string,
  placeholder: PropTypes.string,
  floatingLabelText: PropTypes.string,
  TextFieldProps: PropTypes.shape(),
};

ColorPicker.defaultProps = {
  value: '',
  onChange: () => { },
  convert: DEFAULT_CONVERTER,
  id: '',
  name: '',
  label: '',
  hintText: '',
  placeholder: '',
  floatingLabelText: '',
  TextFieldProps: {},
};

export default ColorPicker;
