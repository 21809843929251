import styled, { css } from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { blue, grey } from '@mui/material/colors';
import colors from '../constants/colors';

export const FlexGrid = MuiStyled(Grid)({
  display: 'flex',
  flexFlow: 'column nowrap',
  boxSizing: 'border-box',
});

export const FlexFlow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  flex-wrap: ${(props) => (props.nowrap ? 'nowrap' : 'wrap')};
  flex-grow: ${(props) => (props.grow ? '1' : '0')};
  flex-shrink: ${(props) => (props.shrink ? '1' : '0')};
  flex-basis: ${(props) => (props.basis || 'auto')};
  margin: ${(props) => (props.margin || '0')};
  padding: ${(props) => (props.padding || '0')};
  align-items: ${(props) => (props.align || 'stretch')};
  justify-content: ${(props) => (props.justify || 'stretch')};

  ${(props) => props.splitSpace && css`
    > * {
      ${!props.column && css`
        margin-left: ${props.splitSpace} !important;
      `}

      ${props.column && css`
        margin-top: ${props.splitSpace} !important;
      `}

      &:first-child {
        ${!props.column && css`
          margin-left: 0 !important;
        `}

        ${props.column && css`
          margin-top: 0 !important;
        `}
      }
    }
  `}
`;

export const ScrollableArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-height: ${(props) => (props.height || '500px')};
  overflow-y: auto;
`;

export const MiniIconBtn = styled(IconButton)`
  border-radius: 0 !important;
  color: inherit !important;
  font-size: inherit !important;
  width: 1em;

  svg {
    height: 0.7em;
    width: 0.7em;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  position: relative;
  font-size: 16px;

  background-color: ${colors.grey50};
  color: ${colors.grey800};
  min-height: calc(100vh);
`;

export const DashboardHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 auto;

  background-color: #ffffff;
  border-bottom: 1px solid ${colors.grey200};
  color: ${colors.grey800};
  padding: 1em;
`;

export const DashboardHero = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 auto;

  background-color: ${colors.grey50};
  color: ${colors.grey800};
  padding: 1em 3em;
`;

export const DashboardContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  font-size: 0.875rem;

  background-color: ${(props) => props.bgColor || 'transparent'};
  color: ${(props) => props.textColor || colors.grey800};

  padding: 0;

  ${(props) => props.padded && css`
    padding: 1em;
  `}

  ${(props) => props.paddedY && css`
    padding: 1em 0;
  `}
`;

export const DashboardTabContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;

  background-color: ${colors.white};
  color: ${colors.grey800};
  padding: 0;
  overflow-x: hidden;
`;

export const Blockquote = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  background-color: ${colors.grey50};
  border-left: 3px solid ${colors.groupizeBrand};
  padding: 0 1em;
`;

export const ModalSectionContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export const PageHeader = styled(DashboardHeader)``;
export const PageHero = styled(DashboardHero)`
  padding: 1em 2em;
`;

export const PageContent = styled(DashboardContent)`
  flex: 1 1 auto;
  max-width: ${(props) => props.maxwidth || '100%'};

  ${(props) => props.padded && css`
    padding: 1em 2em;
  `}

  ${(props) => props.noGrow && css`
    flex-grow: 0;
  `}

  ${(props) => props.borderTop && css`
    border-top: 1px solid ${colors.grey200};
  `}

  ${(props) => props.borderRight && css`
    border-right: 1px solid ${colors.grey200};
  `}

  ${(props) => props.borderLeft && css`
    border-left: 1px solid ${colors.grey200};
  `}
`;

export const PageFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.grey200};
  padding-bottom: 2em;

  ${(props) => props.row && css`
    flex-direction: row;
  `}

  ${(props) => props.shadow && css`
    box-shadow: 0 0 2em 0 ${colors.grey200};
  `}

  ${(props) => props.padded && css`
    padding: 1em 2em;
  `}
`;

export const StickyArea = styled.div`
  position: sticky;
  top: 0;
  z-index: 9;

  ${(props) => props.bottom && css`
    top: auto;
    bottom: ${props.bottom || '0%'};
  `}

  @media only screen and (max-width: 768px) {
    position: relative;
  }
`;

export const Placeholder = styled.div`
  display: none;
  width: ${(props) => props.w || '1em'};

  @media only screen and (max-width: 768px) {
    display: flex;
    flex: 0 0 auto;
  }
`;

export const PageAside = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 350px;

  background-color: #ffffff;
  border-right: 1px solid ${colors.grey200};

  margin-right: 1em;
  padding: 0 1em 1em;
  z-index: 1;

  ${(props) => props.isClosed && css`
    flex: 0 1 50px;
  `}

  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
  }

  .scrollSpy {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;

    list-style: none;
    margin: 0 0 1em;
    padding: 0;

    &.closed a {
      padding-left: 1em;

      > svg {
        color: ${colors.grey400};
      }

      &:before {
        display: none;
      }

      .text {
        display: none;
      }
    }

    li {
      display: flex;
      flex-flow: column nowrap;

      a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        border-bottom: 1px solid ${colors.grey200};
        box-sizing: border-box;
        min-height: 50px;
        padding: 1em;
        position: relative;
        transition: color 0.3s ease-in;

        color: ${colors.grey600};
        font-weight: 600;
        text-decoration: none;
        text-transform: capitalize;

        > svg {
          color: ${colors.grey200};
          flex: 0 0 auto;
          font-size: 1.4em;
          transition: color 0.3s ease-in;
        }

        .text {
          margin-left: 1em;
        }

        &:before {
          /* content: ''; */
          background-color: transparent;
          position: absolute;
          left: 0.5em;
          border-radius: 50%;
          height: 0.75em;
          width: 0.75em;
          transition: all 0.3s ease-in;
        }
      }

      &.active a {
        color: ${colors.groupizeBrand};

        > svg {
          color: ${colors.groupizeBrand};
        }

        &:before {
          background-color: ${colors.groupizeBrand};
        }
      }
    }
  }
`;

export const PageSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  padding: 1em 0;
`;

export const FormLabel = styled.div`
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1.4;
  min-width: 5em;
  text-transform: uppercase;
`;

export const FormTitle = styled.p`
  font-size: 0.9em;
  font-weight: 600;
  line-height: 1.4;
  margin: 0.5em 0;
`;

export const FormText = styled.p`
  font-size: 0.7em;
  font-weight: 300;
  line-height: 1.4;
  margin: 0.5em 0 1em;
`;

export const FormValueNumber = styled.div`
  font-size: 1.8em;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
`;

export const HeroTitle = styled.p`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  margin: 1rem 0;
  padding-bottom: 1rem;
  position: relative;

  color: ${blue[600]};
  font-size: 1.4em;
  font-weight: 600;
  line-height: 1;

  &:after {
    content: '';
    background-color: ${colors.groupizeBrand};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 3rem;
  }

  > svg {
    margin-right: 0.5em;
  }
`;

export const HeroText = styled.p`
  color: ${grey[600]};
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 0 0 0.5rem;

  .title {
    font-weight: 600;
    margin-right: 0.5em;
  }
`;

export const PageSectionTitle = styled(HeroTitle)`
  font-size: 1.2em;
  font-weight: 400;

  &:after {
    display: none;
  }

  > svg {
    color: ${grey[400]};
    font-size: 1.4em;
    margin-right: 0.5em;
  }

  ${(props) => props.nomargin && css`
    margin: 0;
  `}

  ${(props) => props.noPadding && css`
    padding: 0;
  `}
`;

export const PageText = styled.p`
  font-size: 1rem;
  margin: 0 0 0.5em;

  ${(props) => props.blueTitle && css`
    color: ${blue[600]};
    margin-bottom: 1em;
  `}

  ${(props) => props.nomargin && css`
    margin: 0;
  `}
`;

export const ColorButton = styled(Button)`
  background-color: ${(props) => props.bgcolor || colors.groupizeBrand} !important;
  border-color: ${(props) => props.bgcolor || colors.groupizeBrand} !important;
  color: ${(props) => props.textColor || props.textcolor || colors.white} !important;

  &.MuiButton-outlined {
    background-color: transparent !important;
    color: ${(props) => props.bgcolor || colors.groupizeBrand} !important;
  }
`;

export const StyledButton = MuiStyled(Button)((props) => ({
  backgroundColor: props.bgColor || colors.groupizeBrand,
  borderColor: props.bgColor || colors.groupizeBrand,
  color: props.textColor || colors.grey50,
  '&:hover': {
    backgroundColor: props.bgColor || colors.groupizeBrand,
    borderColor: props.bgColor || colors.groupizeBrand,
  },
}));

export const GroupizeBtn = MuiStyled(Button)({
  color: colors.groupizeBrand,
  borderColor: colors.groupizeBrand,
  '&:hover': {
    backgroundColor: colors.grey50,
    boxShadow: 'none',
  },
  '&.MuiButton-contained': {
    backgroundColor: colors.groupizeBrand,
    boxShadow: 'none',
    color: colors.white,
    '&:hover': {
      backgroundColor: '#8bce6f',
      boxShadow: 'none',
    },
  },
});
