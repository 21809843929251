import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
  StaticDatePicker, PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import isSameDay from 'date-fns/isSameDay';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isCurrentDay',
})(({
  theme, isCurrentDay,
}) => ({
  ...(isCurrentDay && {
    backgroundColor: 'var(--gr-brand-color, #73BD5D) !important',
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: 'var(--gr-brand-color, #00ff00) !important',
      opacity: '0.9 !important',
    },
  }),
}));

function StaticDateField({
  value,
  onDateSelected,
}) {
  const [dateValue, setDateValue] = useState(value);

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  const dateSelected = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setDateValue(formattedDate);
    onDateSelected(formattedDate);
  };

  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!dateValue) {
      return <PickersDay {...pickersDayProps} />;
    }

    const thisDate = moment(dateValue).isValid() ? moment(dateValue).toDate() : null;
    const isCurrentDay = isSameDay(date, thisDate);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        isCurrentDay={isCurrentDay}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        allowSameDateSelection
        displayStaticWrapperAs="desktop"
        label="Week picker"
        value={value}
        onChange={(newValue) => {
          dateSelected(newValue);
        }}
        renderDay={renderPickerDay}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="EEE, MMM dd, yyyy"
      />
    </LocalizationProvider>
  );
}

StaticDateField.defaultProps = {
  value: '',
};

StaticDateField.propTypes = {
  value: PropTypes.string,
  onDateSelected: PropTypes.func.isRequired,
};

export default StaticDateField;
