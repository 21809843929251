import React from 'react';
import PropTypes from 'prop-types';
import { AnimationDiv } from './styles';

function Animate({
  children,
  iterations,
  delay,
  duration,
  animation,
}) {
  return (
    <AnimationDiv
      iterations={iterations}
      delay={delay}
      duration={duration}
      animation={animation}
    >
      {children}
    </AnimationDiv>
  );
}

Animate.defaultProps = {
  animation: 'fadeIn',
  iterations: 1,
  delay: '0s',
  duration: '0.3s',
};

Animate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  iterations: PropTypes.number,
  delay: PropTypes.string,
  duration: PropTypes.string,
  animation: PropTypes.string,
};

export default Animate;
